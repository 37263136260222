import {Alert, Button, FormControl, FormField, showNotification} from 'platform/components';
import {Box, Show, Space, Stack, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {validateVin} from 'features/vin-validate';

import {
  useGetRequestQuery,
  useLazyGetRequestVinDetailsQuery,
  useUpdateRequestMutation,
} from '../../../store/priceReportApi';
import {DRIVE_4X2} from '../constants/drive4x2';
import {DRIVE_4X4} from '../constants/drive4x4';
import {VehicleFormType} from '../types/VehicleFormType';
import {useGetRequestVehicleFromFormData} from '../utils/useGetRequestVehicleFromFormData';

interface VinParserProps extends TestIdProps {
  control: FormControl<VehicleFormType>;
  formApi: UseFormReturn<VehicleFormType>;
}

export function VinParser(props: VinParserProps) {
  const {requestId} = useRequiredParams();
  const getRequestVehicleFromFormData = useGetRequestVehicleFromFormData();

  const {data: request} = useGetRequestQuery({id: requestId ?? ''});
  const [getRequestVinDetails, {isLoading, isError, data}] = useLazyGetRequestVinDetailsQuery();
  const [updateRequest] = useUpdateRequestMutation();

  const handleDecodeVin = async () => {
    const vin = props.formApi.getValues('vin');

    if (isNilOrEmpty(vin)) {
      return props.formApi.setError('vin', {
        message: i18n.t('page.create.validation.fieldIsRequired'),
      });
    }

    if (!validateVin(vin)) {
      return props.formApi.setError('vin', {
        message: i18n.t('page.create.validation.vinIsInvalid'),
      });
    }

    const decodedData = await getRequestVinDetails({id: requestId})
      .unwrap()
      .catch(() => showNotification.error(i18n.t('general.labels.error')));

    if (decodedData?.make) {
      props.formApi.setValue('make', decodedData.make);
    }
    if (decodedData?.modelFamily) {
      props.formApi.setValue('modelFamily', decodedData.modelFamily);
    }
    if (decodedData?.firstRegistrationOnYear) {
      props.formApi.setValue(
        'firstRegistrationOnYear',
        parseInt(decodedData.firstRegistrationOnYear)
      );
    }
    if (decodedData?.transmission) {
      props.formApi.setValue('transmission', [decodedData.transmission]);
    }
    if (decodedData?.fuelType) {
      props.formApi.setValue('fuelType', [decodedData.fuelType]);
    }
    if (decodedData?.bodyStyle) {
      props.formApi.setValue('bodyStyle', [decodedData.bodyStyle]);
    }
    if (decodedData?.drive) {
      props.formApi.setValue('drive', [decodedData.drive === DRIVE_4X4 ? DRIVE_4X4 : DRIVE_4X2]);
    }
    if (decodedData?.power) {
      props.formApi.setValue('power', parseInt(decodedData.power));
    }
    if (decodedData?.exteriorColor) {
      props.formApi.setValue('exteriorColor', [decodedData.exteriorColor]);
    }
    if (decodedData?.seatCount) {
      props.formApi.setValue('seatCount', [decodedData.seatCount]);
    }
    if (decodedData?.doorCount) {
      props.formApi.setValue('doorCount', [decodedData.doorCount]);
    }
    if (decodedData?.features) {
      props.formApi.setValue('features', decodedData.features);
    }

    await updateRequest({
      id: requestId,
      body: {vehicle: getRequestVehicleFromFormData(props.formApi.getValues())},
    })
      .unwrap()
      .catch(() => showNotification.error(i18n.t('general.labels.error')));
  };

  const isDecodedSuccesfully =
    data?.decodingStatus === 'partially' || data?.decodingStatus === 'fully';
  const isDecodedUnsuccesfully = data?.decodingStatus === 'none' || isError;

  return (
    <VStack spacing={6}>
      <Stack direction={['column', 'column', 'row', 'row']} spacing={6}>
        <Box flex={1}>
          <FormField
            control={props.control}
            type="text"
            name="vin"
            label={`${i18n.t('page.create.labels.vin')} (${i18n.t('general.labels.optional')})`}
            onChange={() => props.formApi.clearErrors('vin')}
            data-testid={suffixTestId('vin', props)}
          />
        </Box>
        <VStack>
          <Show onDesktop onNotebook>
            <Space vertical={5} />
          </Show>
          <Button
            title={i18n.t('page.create.labels.loadDataByVIN')}
            onClick={handleDecodeVin}
            isLoading={isLoading}
            isDisabled={isTrue(request?.vinDecoded)}
            data-testid={suffixTestId('loadDataByVIN', props)}
          />
        </VStack>
      </Stack>
      <Show when={isDecodedSuccesfully}>
        <Alert
          type="inline"
          variant="warning"
          title={i18n.t('page.create.labels.vinCodeRecognized')}
          message={i18n.t('page.create.labels.vinCodeRecognizedMessage')}
          data-testid={suffixTestId('vinCodeRecognized', props)}
        />
      </Show>
      <Show when={isDecodedUnsuccesfully}>
        <Alert
          type="inline"
          variant="warning"
          title={i18n.t('page.create.labels.vinCodeNotRecognized')}
          message={i18n.t('page.create.labels.vinCodeNotRecognizedMessage')}
          data-testid={suffixTestId('vinCodeNotRecognized', props)}
        />
      </Show>
    </VStack>
  );
}
