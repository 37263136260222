import {Box, Display, HStack, Image, Text, VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

export function ErrorPage(props: TestIdProps) {
  return (
    <Box backgroundColor="palettes.blue.70.100" height="100vh">
      <HStack justify="center" align="center" height="100%">
        <Box maxWidth={100} padding={6}>
          <VStack spacing={6} align="center">
            <Image
              src="assets/images/logo.svg"
              width={38}
              data-testid={suffixTestId('notFound', props)}
            />
            <Display size={1} color="white" data-testid={suffixTestId('notFound', props)}>
              404
            </Display>
            <Text color="white" align="center" data-testid={suffixTestId('notFound', props)}>
              {i18n.t('page.error.labels.notFound')}
            </Text>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}
