import {CurrencyCodeType, Nullish} from 'shared';

import {filterByMinMax} from './filterByMinMax';

const PRICE_VALUES: Partial<Record<CurrencyCodeType, number[]>> = {
  CZK: [
    15000, 30000, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 500000,
    750000, 1000000, 2000000, 4000000,
  ],
  EUR: [
    500, 1000, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000,
    80000, 90000, 100000,
  ],
  PLN: [
    5000, 10000, 15000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 150000,
    200000, 300000, 400000,
  ],
  RON: [
    5000, 10000, 15000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 150000,
    200000, 300000, 400000,
  ],
};

export const getPriceOptions = (
  currency: CurrencyCodeType,
  min?: number | Nullish,
  max?: number | Nullish
): number[] => {
  const prices = PRICE_VALUES[currency] ?? PRICE_VALUES.EUR ?? [];
  return filterByMinMax(min, max)(prices);
};
