import {Rank} from 'platform/components';
import {Heading, Hide, HStack, Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {CompareTrend} from '../../../components/CompareTrend/CompareTrend';

interface VerticalValueProps extends TestIdProps {
  value: number | null | undefined;
  isMyVehicle: boolean;
  formatter?: (value: number | null | undefined) => string;
  withRank?: boolean;
  difference?: number;
  differenceReverseColor?: boolean;
  differenceFormatter?: (value: number | null | undefined) => string;
  isRow?: boolean;
}

export function VerticalValue(props: VerticalValueProps) {
  const percentage = isNotNil(props.value) ? Math.floor(props.value * 100) : null;

  return (
    <VStack spacing={1}>
      <HStack spacing={2} align="center">
        <Hide when={props.isRow}>
          <Text size="xSmall" data-testid={suffixTestId('value', props)}>
            {props.formatter?.(props.value) ?? `${props.value ?? '-'}`}
          </Text>
        </Hide>
        <Show when={props.isRow}>
          <Heading size={5} alternative data-testid={suffixTestId('value', props)}>
            {props.formatter?.(props.value) ?? `${props.value ?? '-'}`}
          </Heading>
        </Show>
        <Show when={props.withRank}>
          <Rank percentage={percentage} />
        </Show>
      </HStack>
      <Hide when={props.isMyVehicle}>
        <CompareTrend
          value={props.difference ?? 0}
          formatter={props.differenceFormatter ?? props.formatter}
          reverseColor={props.differenceReverseColor}
        />
      </Hide>
    </VStack>
  );
}
