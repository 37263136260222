import {BaseFlagProps, FlagLabel, getFlagElementSizes} from 'platform/components';
import {Box, Center, ThemeColorPath} from 'platform/foundation';

export interface FlagProps extends BaseFlagProps {
  backgroundColor: ThemeColorPath;
  color: ThemeColorPath;
}

export function Flag(props: FlagProps) {
  const {height, labelHorizontalPadding, textSize, borderRadius} = getFlagElementSizes();

  return (
    <Box
      height={height}
      paddingHorizontal={labelHorizontalPadding}
      backgroundColor={props.backgroundColor}
      borderRadius={borderRadius}
      maxWidth="100%"
    >
      <Center height="100%">
        <FlagLabel color={props.color} size={textSize} label={props.label} />
      </Center>
    </Box>
  );
}
