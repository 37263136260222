import {Segment} from 'platform/components';
import {Hide, Icon} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Direction} from '../types/direction';

const options: {value: Direction; label: ReactNode}[] = [
  {value: 'column', label: <Icon value="action/view_column" size={4} />},
  {value: 'row', label: <Icon value="action/view_list" size={4} />},
];

interface DirectionSwitchProps extends TestIdProps {
  direction: Direction;
  setDirection: (value: Direction) => void;
}

export function DirectionSwitch(props: DirectionSwitchProps) {
  return (
    <Hide onMobile>
      <Segment
        onChange={props.setDirection}
        value={props.direction}
        options={options}
        data-testid={suffixTestId('directionSwitch', props)}
      />
    </Hide>
  );
}
