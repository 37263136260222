import {DefaultFoundationTheme} from 'platform/foundation';

import {ExtendedStatisticsCar} from '../types';
import {isOnStock} from './isOnStock';
import {isSoldIn30Days} from './isSoldIn30Days';

export function getChartItemColor(
  theme: DefaultFoundationTheme,
  car: ExtendedStatisticsCar,
  isFocused: boolean,
  isSelected: boolean
): string {
  if (isSelected) {
    return theme.colors.general.accent;
  }
  if (isFocused) {
    return theme.colors.palettes.blue['40']['100'];
  }
  if (car.isDebugVehicle) {
    return theme.colors.palettes.red['60']['100'];
  }
  if (isOnStock(car)) {
    return theme.colors.palettes.orange['60']['100'];
  }
  if (isSoldIn30Days(car)) {
    return theme.colors.palettes.neutral['300']['100'];
  }
  return theme.colors.palettes.neutral['40']['100'];
}
