import {i18n} from '@price-report/shared';

export function getTurnoverRange(turnover: number): string {
  if (turnover <= 11) {
    return i18n.t('general.labels.daysRange', {from: 10, count: 30});
  }

  const roundedTurnover = Math.ceil(turnover / 10) * 10;
  return i18n.t('general.labels.daysRange', {
    from: roundedTurnover - 10,
    count: roundedTurnover + 10,
  });
}
