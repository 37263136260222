import {Box, getSize, Integer, ValueByDevice} from 'platform/foundation';
import styled from 'styled-components';

import {PropsWithChildren} from 'react';

const ScrollWithoutScrollbar = styled.div<{$direction: 'x' | 'y'}>`
  overflow-${(props) => props.$direction}: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Webkit based browsers */
  }
`;

interface HorizontalScrollWithGradientsProps extends PropsWithChildren {
  paddingHorizontal?: Integer | ValueByDevice<Integer>;
}

export function HorizontalScrollWithGradient(props: HorizontalScrollWithGradientsProps) {
  return (
    <Box position="relative">
      <ScrollWithoutScrollbar $direction="x">
        <Box paddingHorizontal={props.paddingHorizontal} width="fit-content">
          {props.children}
        </Box>
      </ScrollWithoutScrollbar>
      <Gradient direction="left" />
      <Gradient direction="right" />
    </Box>
  );
}

const Gradient = styled('div')<{direction: 'left' | 'right'}>`
  background: linear-gradient(
    to ${({direction}) => direction},
    rgba(0, 0, 0, 0),
    ${({theme}) => theme.colors.palettes.blue[70][100]}
  );
  width: ${getSize(3)};
  height: 100%;
  position: absolute;
  top: 0;
  ${({direction}) => direction}: 0;
`;
