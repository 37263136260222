import {AvailableCar} from '@price-report/shared';

import {ExtendedStatisticsCar} from '../types';

export const mapAvailableCarToExtendedStatisticCar = (
  car: AvailableCar
): ExtendedStatisticsCar => ({
  ad_id: car.ad_id ?? '',
  days_on_stock: car.days_on_stock ?? 0,
  feature_score: car.feature_score ?? 0,
  is_available: true, // car is type of AvailableCar
  is_sold: car.is_sold ?? false,
  last_occurrence: car.last_occurrence ?? '',
  mileage: car.mileage ?? 0,
  price_with_vat: car.price_with_vat ?? 0,
  original_price_with_vat: car.original_price_with_vat ?? 0,
  position: 0,
  isDebugVehicle: true,
});
