import {Box, HStack, Icon, Text, ThemeColorPath} from 'platform/foundation';

import {TestIdProps} from 'shared';

interface RealSellingPriceBannerProps extends TestIdProps {
  variant: 'warning' | 'success';
  message?: string;
  heading: string;
}

export function RealSellingPriceBanner(props: RealSellingPriceBannerProps) {
  const messageColor: ThemeColorPath =
    props.variant === 'success' ? 'palettes.green.20.100' : 'palettes.yellow.20.100';

  return (
    <Box
      backgroundColor={messageColor}
      borderRadius="small"
      paddingVertical={3}
      paddingHorizontal={4}
    >
      <HStack spacing={2} align="center">
        <Icon
          value={props.variant === 'success' ? 'action/trending_up' : 'action/trending_down'}
          color={props.variant === 'success' ? 'severity.success' : 'severity.warning'}
          size={4}
        />
        <HStack>
          <Text alternative size="small">
            {props.heading}
            {props.message && (
              <Text inline size="small">
                {` ${props.message}`}
              </Text>
            )}
          </Text>
        </HStack>
      </HStack>
    </Box>
  );
}
