import {Box, Integer} from 'platform/foundation';
import {useTheme} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {ReactNode, useEffect, useRef} from 'react';

import {noop} from 'ramda-adjunct';

import {useHeaderHeight} from '../../hooks/useHeaderHeight';

interface StickyContainerProps {
  offset?: Integer;
  isDisabled?: boolean;
  children: ReactNode | ReactNode[];
}

const THRESHOLD = 1;

export function StickyContainer(props: StickyContainerProps) {
  const theme = useTheme();
  const headerHeight = useHeaderHeight();

  const containerRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const lastScrollPosition = useRef(0);

  const setPosition = (position: 'relative' | 'sticky') =>
    componentRef.current?.style.setProperty('position', position);
  const setTop = (top: number) => componentRef.current?.style.setProperty('top', `${top}px`);

  useEffect(() => {
    const handleScroll = () => {
      const offset = (props.offset ?? 0) / theme.space.step;

      const componentBoundingClientRect = componentRef?.current?.getBoundingClientRect();
      const containerTop = containerRef?.current?.getBoundingClientRect().top ?? 0;
      const componentTop = componentBoundingClientRect?.top ?? 0;
      const componentHeight = componentBoundingClientRect?.height ?? 0;

      const absoluteTop = Math.abs(containerTop - componentTop);
      const stickyTop = window.innerHeight - componentHeight - offset;

      const isBottomVisible =
        componentTop + componentHeight < window.innerHeight - offset + THRESHOLD;
      const isTopVisible = componentTop >= offset + headerHeight - THRESHOLD;

      const currentScrollPosition = document.documentElement.scrollTop;
      const isScrollingUp = currentScrollPosition < lastScrollPosition.current;
      lastScrollPosition.current = currentScrollPosition;

      if (componentHeight + (offset - THRESHOLD) * 2 < window.innerHeight - headerHeight) {
        setPosition('sticky');
        setTop(offset + headerHeight);
        return;
      }

      match([isScrollingUp, isTopVisible, isBottomVisible])
        .with([true, true, Pattern.boolean], () => {
          setPosition('sticky');
          setTop(offset + headerHeight);
        })
        .with([true, false, Pattern.boolean], () => {
          setPosition('relative');
          setTop(absoluteTop);
        })
        .with([false, Pattern.boolean, true], () => {
          setPosition('sticky');
          setTop(stickyTop);
        })
        .with([false, Pattern.boolean, false], () => {
          setPosition('relative');
          setTop(absoluteTop);
        })
        .otherwise(noop);
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [headerHeight, props.offset, theme]);

  if (props.isDisabled) {
    return <>{props.children}</>;
  }

  return (
    <Box ref={containerRef} position="relative" width="100%" height="100%">
      <div ref={componentRef}>{props.children}</div>
    </Box>
  );
}
