import {Box, useDevice} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';
import {Bar, ComposedChart, Customized, ResponsiveContainer, XAxis} from 'recharts';
import {useTheme} from 'styled-components';

import {last} from 'ramda';

import {DrillDownResponse, VehicleType} from '@price-report/shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {useCatalogue} from '../../../../../hooks/useCatalogue';
import {ThisVehicleTooltip, TOOLTIP_HEIGHT} from '../../../components/ThisVehicleTooltip';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';
import {ColorBar} from './ColorBar';
import {SilverGradientDefinition} from './SilverGradientDefinition';

interface ColorChartProps {
  vehicle: VehicleType;
  colorChartData?: DrillDownResponse;
}

export function ColorChart({vehicle, colorChartData}: ColorChartProps) {
  const theme = useTheme();
  const formatPercentage = useFormatPercentage();
  const catalogue = useCatalogue();
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();
  const isSmallDevice = device === 'mobile' || device === 'tablet';

  const data =
    colorChartData?.statisticsAggregated?.map((item) => ({
      name: last(item.levels)?.value,
      count: item.statistics.reduce((acc, curr) => acc + curr.amount, 0),
    })) ?? [];

  const sum = data.reduce((acc, curr) => acc + curr.count, 0);

  const chartData = data
    ?.map((item) => ({
      share: item.count / sum,
      code: item.name,
      name: catalogue.getBodyColor(item.name),
      fill: catalogue.getBodyColorHEX(item.name),
    }))
    ?.filter((item) => item.name.substring(0, 9) !== 'CARSTYLE_')
    .sort((a, b) => b.share - a.share);

  const xPositionOfThisVehicle = chartData
    .map((item) => item.code)
    .indexOf(vehicle.exteriorColor ?? '');

  const formatPercentageForChart = (value: number) =>
    value < 0.01 && value > 0 ? '<1 %' : formatPercentage(value, 'nearest');

  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false);

  return (
    <Box>
      <ResponsiveContainer width={chartWidth} height={250}>
        <ComposedChart
          data={chartData}
          margin={
            isSmallDevice
              ? {bottom: 40, right: 20, top: TOOLTIP_HEIGHT + 16}
              : {top: TOOLTIP_HEIGHT + 16}
          }
        >
          <Customized component={<SilverGradientDefinition />} />
          <Bar
            shape={<ColorBar />}
            dataKey="share"
            fill={theme.colors.palettes.neutral[60][100]}
            radius={2}
            minPointSize={2}
            label={({x, y, width, value}) => (
              <text
                x={x + width / 2}
                y={y}
                textAnchor="middle"
                dy={-6}
                fontSize={12}
                fill={theme.colors.palettes.neutral[200][100]}
              >
                {formatPercentageForChart(value)}
              </text>
            )}
            isAnimationActive={isAnimationActive}
          />
          <XAxis
            dataKey="name"
            fontSize={12}
            tick={{fill: theme.colors.text.primary}}
            axisLine={false}
            tickLine={false}
            padding={{left: 20, right: 20}}
            angle={isSmallDevice ? 60 : 0}
            textAnchor={isSmallDevice ? 'start' : 'middle'}
          />
          {xPositionOfThisVehicle >= 0 && (
            <Customized
              component={
                <ThisVehicleTooltip xPositionOfThisVehicle={xPositionOfThisVehicle} yOffset={6} />
              }
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}
