import {Box, HStack, Icon, Show, Space} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect, useRef} from 'react';

import {Step} from './Step';

const ScrollWithoutScrollbar = styled.div<{$direction: 'x' | 'y'}>`
  overflow-${(props) => props.$direction}: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Webkit based browsers */
  }
`;

interface StepperProps {
  steps: string[];
  value?: number | null;
  onChange?: (value: number) => void;
}

export function Stepper(props: StepperProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const selectedStep = props.value ?? 0;

  useEffect(() => {
    containerRef.current?.scrollTo({
      left: selectedStep === 0 ? 0 : contentRef.current?.scrollWidth,
      behavior: 'smooth',
    });
  }, [selectedStep]);

  return (
    <ScrollWithoutScrollbar $direction="x" ref={containerRef}>
      <Box ref={contentRef}>
        <HStack>
          {props.steps.map((step, index) => (
            <HStack key={step}>
              <Show when={index > 0}>
                <Space horizontal={[4, 8, 8, 8]} />
                <Icon value="navigation/arrow_forward_ios" size={6} color="palettes.blue.50.100" />
                <Space horizontal={[4, 8, 8, 8]} />
              </Show>
              <Step text={step} index={index} value={selectedStep} />
            </HStack>
          ))}
        </HStack>
      </Box>
    </ScrollWithoutScrollbar>
  );
}
