import {Flag} from 'platform/components';
import {Hide, HStack, Text, VStack} from 'platform/foundation';

import {Trans} from 'react-i18next';

import {isNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

interface MissingFeaturesProps extends TestIdProps {
  features?: string[];
}

export function MissingFeatures(props: MissingFeaturesProps) {
  return (
    <VStack spacing={6}>
      <Text color="secondary" size="xSmall">
        <Trans
          i18nKey="entity.overviewStatistics.labels.missingFeatures"
          components={{
            bold: <Text alternative inline color="secondary" size="xSmall" />,
          }}
        />
      </Text>
      <Hide when={isNilOrEmpty(props.features)}>
        <HStack wrap spacing={2}>
          {props.features?.map((feature) => (
            <Flag
              key={feature}
              label={feature}
              isSubtle
              data-testid={suffixTestId('feature', props)}
            />
          ))}
        </HStack>
      </Hide>
    </VStack>
  );
}
