import {Box, Hide, Space, useDevice} from 'platform/foundation';
import {Bar, ComposedChart, Customized, ResponsiveContainer, XAxis} from 'recharts';
import {useTheme} from 'styled-components';

import {reverse} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {DrillDownResponse} from '@price-report/shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {ChartLegend} from '../../../components/ChartLegend';
import {ThisVehicleTooltip} from '../../../components/ThisVehicleTooltip';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';
import {TOOLTIP_HEIGHT} from '../constants/tooltipHeight';

interface YearChartProps {
  registrationYearChart?: DrillDownResponse;
  vehicleYear: number;
}

export function YearChart(props: YearChartProps) {
  const theme = useTheme();
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();

  const chartData =
    reverse(props.registrationYearChart?.statisticsAggregated ?? []).map((item) => ({
      name: item.levels.find((level) => level.name === 'year')?.value,
      stock: item.statistics.find((i) => i.type === 'stock')?.amount ?? 0,
      sold: item.statistics.find((i) => i.type === 'sold')?.amount ?? 0,
    })) ?? [];

  const xPositionOfThisVehicle = chartData
    .map((item) => item.name)
    .indexOf(String(props.vehicleYear));

  const isSmallDevice = device === 'mobile' || device === 'tablet';
  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false);

  return (
    <Hide when={isNilOrEmpty(props.registrationYearChart?.statisticsAggregated)}>
      <Box>
        <ChartLegend />
        <Space vertical={2} />
        <ResponsiveContainer width={chartWidth} height={250}>
          <ComposedChart
            data={chartData}
            margin={
              isSmallDevice
                ? {bottom: 10, right: 20, top: TOOLTIP_HEIGHT + 16}
                : {top: TOOLTIP_HEIGHT + 16}
            }
            /* CSS is needed to allow line label overflow from the chart, because it is moved up (`dy={-6}`) */
            /* eslint-disable-next-line react/forbid-component-props */
            css={`
              .recharts-surface {
                overflow: visible;
              }
            `}
          >
            <Bar
              dataKey="sold"
              fill={theme.colors.palettes.neutral[60][100]}
              radius={2}
              minPointSize={2}
              label={({x, y, width, value}) => (
                <text
                  x={x + width / 2}
                  y={y}
                  textAnchor="middle"
                  dy={-6}
                  fontSize={12}
                  fill={theme.colors.palettes.neutral[200][100]}
                >
                  {value}
                </text>
              )}
              isAnimationActive={isAnimationActive}
            />
            <Bar
              dataKey="stock"
              fill={theme.colors.severity.warning}
              radius={2}
              minPointSize={2}
              label={({x, y, width, value}) => (
                <text
                  x={x + width / 2}
                  y={y}
                  textAnchor="middle"
                  dy={-6}
                  fontSize={12}
                  fill={theme.colors.palettes.orange[80][100]}
                >
                  {value}
                </text>
              )}
              isAnimationActive={isAnimationActive}
            />
            <XAxis
              dataKey="name"
              fontSize={12}
              fontWeight="bold"
              axisLine={false}
              tickLine={false}
              dy={5}
              minTickGap={0}
              tick={{fill: theme.colors.text.primary}}
              angle={isSmallDevice ? 60 : 0}
              textAnchor={isSmallDevice ? 'start' : 'middle'}
            />
            {xPositionOfThisVehicle >= 0 && (
              <Customized
                component={<ThisVehicleTooltip xPositionOfThisVehicle={xPositionOfThisVehicle} />}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Hide>
  );
}
