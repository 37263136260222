import {map, pipe, range} from 'ramda';

import {Nullish} from 'shared';

import {filterByMinMax} from './filterByMinMax';

const YEAR_VALUES = [1960, 1970, 1980, 1985, ...range(1990, new Date().getFullYear() + 1)];

export const getYearOptions = (min?: number | Nullish, max?: number | Nullish) =>
  pipe(
    filterByMinMax(min, max),
    map((value: number) => ({label: `${value}`, value}))
  )(YEAR_VALUES);
