export function getStatisticVariant(
  sold: number,
  stock: number
): 'success' | 'neutral' | 'warning' {
  if (sold > stock) {
    return 'success';
  }
  if (sold === stock) {
    return 'neutral';
  }
  return 'warning';
}
