import {generatePath} from 'react-router';
import {Params} from 'react-router-dom';

import {concat, curry, join, map, pipe, replace, trim} from 'ramda';

const pathSeparator = '/';
const pathGenerator = curry<(paths: string, params: Params<string> | undefined) => string>(
  (paths, params) => {
    const path = generatePath(paths, params);

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const searchParamsString = searchParams.toString();

    return searchParamsString ? `${path}?${searchParamsString}` : path;
  }
);
/**
 * Makes pathname from array of string
 */
export const makePath = pipe(
  map(trim),
  join(pathSeparator),
  concat(pathSeparator),
  replace(/\/{2,}/g, pathSeparator)
);

/**
 * Routes
 */
export const reportRoute = ':requestId';
export const overviewRoute = 'overview';
export const drillDownRoute = 'drill-down';
export const advertisementRoute = 'advertisement';
export const statisticsRoute = 'statistics';
export const createRoute = 'create';

/**
 * Routes
 */
export const overviewPath = makePath([reportRoute, overviewRoute]);
export const drillDownPath = makePath([reportRoute, drillDownRoute]);
export const advertisementPath = makePath([reportRoute, advertisementRoute]);
export const statisticsPath = makePath([reportRoute, statisticsRoute]);
export const createPath = makePath([reportRoute, createRoute]);

/**
 * Route generators
 */
export const getOverviewPath = (params?: Params<string>) => pathGenerator(overviewPath, params);
export const getDrillDownPath = (params?: Params<string>) => pathGenerator(drillDownPath, params);
export const getAdvertisementPath = (params?: Params<string>) =>
  pathGenerator(advertisementPath, params);
export const getStatisticsPath = (params?: Params<string>) => pathGenerator(statisticsPath, params);
export const getCreatePath = (params?: Params<string>) => pathGenerator(createPath, params);
