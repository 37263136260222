import {Align, Box, getSize, VStack} from 'platform/foundation';
import {CurrencyFormat} from 'platform/locale';
import styled from 'styled-components';

import {i18n} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useIsPrintForced} from '../../../hooks/useIsPrintForced';
import {getValueOrEmptyString} from '../../../utils/getValueOrEmptyString';

// eslint-disable-next-line eag/no-css-property
const ChartText = styled.div<{$color: string}>`
  font-family: ${({theme}) => theme.fonts.heading};
  font-size: ${getSize(3)};
  font-weight: 600;
  color: ${(props) => props.$color};
`;

const ForeignObject = styled.foreignObject`
  text-align: center;
`;

// eslint-disable-next-line eag/no-css-property
const RecommendedPrice = styled.span<{$isPrintForced: boolean}>`
  font-size: 2.25rem;
  color: #3e47dd;
  font-family: ${({theme}) => theme.fonts.heading};
  font-weight: 700;

  @media only screen and (width <= 1024px) {
    ${(props) => (props.$isPrintForced ? '' : 'font-size: 1.25rem;')}
  }
`;

// eslint-disable-next-line eag/no-css-property
const OtherPrice = styled.span<{$position: 'left' | 'right'; $isPrintForced: boolean}>`
  position: absolute;
  top: 12px;
  ${(props) => (props.$position === 'left' ? 'right' : 'left')}: 75%;

  font-size: 1.125rem;
  color: #3e47dd;
  font-family: ${({theme}) => theme.fonts.heading};
  font-weight: 700;

  @media only screen and (width <= 1024px) {
    ${(props) => (props.$isPrintForced ? '' : 'font-size: 0.75rem;')}
  }
`;

interface Price {
  value: number;
  currency: string;
}

interface CarvagoPriceChartProps extends TestIdProps {
  minPrice: Price | Nullish;
  maxPrice: Price | Nullish;
  recommendedPrice: Price | Nullish;
}

export function CarvagoPriceChart(props: CarvagoPriceChartProps) {
  const {isPrintForced} = useIsPrintForced();

  return (
    <VStack width="100%" maxWidth={125}>
      <Align center>
        <RecommendedPrice
          $isPrintForced={isPrintForced}
          data-testid={suffixTestId('recommendedPrice', props)}
        >
          {getValueOrEmptyString(props.recommendedPrice, (recommendedPrice) => (
            <CurrencyFormat number={recommendedPrice.value} currency={recommendedPrice.currency} />
          ))}
        </RecommendedPrice>
      </Align>
      <Box position="relative">
        <OtherPrice
          $isPrintForced={isPrintForced}
          data-testid={suffixTestId('minPrice', props)}
          $position="left"
        >
          {getValueOrEmptyString(props.minPrice, (minPrice) => (
            <CurrencyFormat number={minPrice.value} currency={minPrice.currency} />
          ))}
        </OtherPrice>
        <OtherPrice
          $isPrintForced={isPrintForced}
          data-testid={suffixTestId('maxPrice', props)}
          $position="right"
        >
          {getValueOrEmptyString(props.maxPrice, (maxPrice) => (
            <CurrencyFormat number={maxPrice.value} currency={maxPrice.currency} />
          ))}
        </OtherPrice>
      </Box>
      <Box width="100%">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 465 323"
          fill="none"
          overflow="visible"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M51.5294 291.165C40.3033 261.92 36.3645 230.382 40.0531 199.274C43.7418 168.166 54.947 138.424 72.7012 112.615C90.4554 86.8064 114.225 65.7071 141.957 51.1394C169.69 36.5716 200.551 28.9731 231.877 29.0001C263.202 29.0271 294.051 36.6787 321.758 51.2943C349.465 65.9098 373.198 87.05 390.908 112.889C408.618 138.729 419.772 168.49 423.407 199.604C427.042 230.718 423.049 262.25 411.772 291.475L231.71 222L51.5294 291.165Z"
            fill="#EDF1F8"
          />
          <path
            d="M32.1623 298.599C30.484 299.244 28.5984 298.405 27.9794 296.717C10.1829 248.194 10.2798 194.869 28.3203 146.36C46.3608 97.8504 81.1306 57.4209 126.309 32.3173C127.88 31.4442 129.856 32.0415 130.705 33.6258C131.555 35.2101 130.957 37.1814 129.386 38.0552C85.6043 62.4092 51.9099 101.605 34.422 148.629C16.934 195.653 16.8289 247.341 34.0577 294.384C34.676 296.072 33.8406 297.955 32.1623 298.599Z"
            fill="url(#paint0_linear_1742_206539)"
          />
          <path
            d="M431.259 298.599C432.937 299.244 434.822 298.405 435.441 296.717C453.238 248.194 453.141 194.869 435.101 146.36C417.06 97.8504 382.29 57.4209 337.112 32.3173C335.541 31.4442 333.565 32.0415 332.716 33.6258C331.866 35.2101 332.464 37.1814 334.035 38.0552C377.817 62.4092 411.511 101.605 428.999 148.629C446.487 195.653 446.592 247.341 429.363 294.384C428.745 296.072 429.58 297.955 431.259 298.599Z"
            fill="url(#paint1_linear_1742_206539)"
          />
          <path
            d="M51.5294 291.165C34.5373 246.899 34.4348 197.927 51.2413 153.59L231.71 222L51.5294 291.165Z"
            fill="#A8D98D"
          />
          <path
            d="M411.891 291.165C428.884 246.899 428.986 197.927 412.18 153.59L231.71 222L411.891 291.165Z"
            fill="#FD9696"
          />
          <path
            d="M51.1697 153.779C68.5551 107.77 102.85 70.1344 147.05 48.5595L231.71 222L51.1697 153.779Z"
            fill="#C9E5AC"
          />
          <path
            d="M412.251 153.779C394.797 107.589 360.303 69.8448 315.866 48.3139L231.71 222L412.251 153.779Z"
            fill="#FFC799"
          />
          <path
            d="M137.899 29.6581C167.028 15.451 199.003 8.04547 231.412 8.00021C263.82 7.95494 295.816 15.2712 324.984 29.3968L231.71 222L137.899 29.6581Z"
            fill="#FFDE85"
          />
          <g filter="url(#filter0_d_1742_206539)">
            <path
              d="M319.71 221C319.71 235.404 316.25 248.999 310.115 261H153.306C147.171 248.999 143.71 235.404 143.71 221C143.71 172.399 183.109 133 231.71 133C280.312 133 319.71 172.399 319.71 221Z"
              fill="white"
            />
          </g>
          <circle cx="138.71" cy="29" r="4" fill="#FFBB35" />
          <circle cx="231.71" cy="8" r="8" fill="#DB9826" />
          <circle cx="324.71" cy="29" r="4" fill="#FFBB35" />
          <ForeignObject x={196} y={56} width={70} height={50}>
            <ChartText $color="#7A450A">
              {i18n.t('entity.priceMeter.labels.priceRangeCaptions_3')}
            </ChartText>
          </ForeignObject>
          <ForeignObject x={56} y={203} width={60} height={50}>
            <ChartText $color="#175808">
              {i18n.t('entity.priceMeter.labels.priceRangeCaptions_1')}
            </ChartText>
          </ForeignObject>
          <ForeignObject x={343} y={203} width={60} height={50}>
            <ChartText $color="#78082F">
              {i18n.t('entity.priceMeter.labels.priceRangeCaptions_5')}
            </ChartText>
          </ForeignObject>
          <ForeignObject x={92} y={109} width={65} height={50}>
            <ChartText $color="#175808">
              {i18n.t('entity.priceMeter.labels.priceRangeCaptions_2')}
            </ChartText>
          </ForeignObject>
          <ForeignObject x={305} y={109} width={60} height={50}>
            <ChartText $color="#932A00">
              {i18n.t('entity.priceMeter.labels.priceRangeCaptions_4')}
            </ChartText>
          </ForeignObject>
          <text
            transform="translate(396 303)"
            fill="#485885"
            xmlSpace="preserve"
            style={{whiteSpace: 'pre'}}
            fontFamily="EuclidCircularB, Roboto, sans-serif"
            fontSize="12"
            letterSpacing="0px"
          >
            <tspan x="0.357422" y="13.54">
              {i18n.t('entity.priceMeter.labels.priceTooHigh')}
            </tspan>
          </text>
          <text
            transform="translate(0 303)"
            fill="#485885"
            xmlSpace="preserve"
            style={{whiteSpace: 'pre'}}
            fontFamily="EuclidCircularB, Roboto, sans-serif"
            fontSize="12"
            letterSpacing="0px"
          >
            <tspan x="0.332031" y="13.54">
              {i18n.t('entity.priceMeter.labels.priceTooLow')}
            </tspan>
          </text>
          <circle cx="432" cy="295" r="4" fill="#D82030" />
          <circle cx="31" cy="295" r="4" fill="#246B0E" />
          <path d="M155.5 251L52 291H411.5L307 251H155.5Z" fill="white" />
          <defs>
            <filter
              id="filter0_d_1742_206539"
              x="119.71"
              y="109"
              width="224"
              height="176"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="12" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1742_206539"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1742_206539"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1742_206539"
              x1="231.71"
              y1="5"
              x2="231.71"
              y2="439"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A8D98D" stopOpacity="0.5" />
              <stop offset="0.666265" stopColor="#5E993D" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1742_206539"
              x1="231.71"
              y1="5"
              x2="231.71"
              y2="439"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F6B666" stopOpacity="0.5" />
              <stop offset="0.666265" stopColor="#FD6C6C" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    </VStack>
  );
}
