import {Button} from 'platform/components';
import {Box, Display, HStack, Image, Text, VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

export function ExpiredPage(props: TestIdProps) {
  return (
    <Box backgroundColor="palettes.blue.70.100" height="100vh">
      <HStack justify="center" align="center" height="100%">
        <Box maxWidth={100}>
          <VStack spacing={6} align="center">
            <Image
              src="assets/images/logo.svg"
              width={38}
              data-testid={suffixTestId('logo', props)}
            />
            <Box paddingHorizontal={[6, 0]}>
              <Image
                src="assets/images/expired.svg"
                data-testid={suffixTestId('expiredIllustration', props)}
              />
            </Box>
            <Box paddingHorizontal={6}>
              <Display
                size={1}
                color="white"
                align="center"
                data-testid={suffixTestId('expired', props)}
              >
                {i18n.t('page.expired.labels.expired')}
              </Display>
            </Box>
            <Box paddingHorizontal={6}>
              <Text
                color="white"
                align="center"
                data-testid={suffixTestId('expiredDescription', props)}
              >
                {i18n.t('page.expired.labels.expiredDescription')}
              </Text>
            </Box>
            <Box paddingHorizontal={6}>
              <Button
                variant="primary"
                title={i18n.t('page.expired.labels.generateNew')}
                onClick={() => {
                  window.location.href = 'https://cz.cebia.com/';
                }}
                data-testid={suffixTestId('linkToCebia', props)}
              />
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}
