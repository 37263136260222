import {Card} from 'platform/components';
import {Align, Box, Heading, Hide, HStack, Icon, Text, VStack} from 'platform/foundation';

import {Trans} from 'react-i18next';

import {isNil} from 'ramda';

import {i18n, MarketType, VehicleType} from '@price-report/shared';

import {TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {ColorChart} from './components/ColorChart';

interface StatisticsCardColorProps extends TestIdProps {
  vehicle: VehicleType;
  market?: MarketType;
}

export function StatisticsCardColor(props: StatisticsCardColorProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();

  return (
    <Hide when={isNil(props.market?.colorChart)}>
      <AvoidBreakInside>
        <Card variant={isPrint ? 'inlineWhite' : undefined}>
          <Box padding={2}>
            <VStack spacing={6}>
              <Align center>
                <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.colorTitle')}</Heading>
              </Align>
              <Text align="center" size="small" color="secondary">
                <Trans
                  i18nKey="entity.overviewStatistics.labels.colorDescription"
                  components={{bold: <Text alternative inline size="small" />}}
                />
              </Text>

              <ColorChart vehicle={props.vehicle} colorChartData={props.market?.colorChart} />

              <Box backgroundColor="palettes.neutral.20.100" borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Icon value="action/info_outline" />
                  <Text
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                    size="small"
                  >
                    {i18n.t('entity.overviewStatistics.labels.colorAlert')}
                  </Text>
                </HStack>
              </Box>
            </VStack>
          </Box>
        </Card>
      </AvoidBreakInside>
    </Hide>
  );
}
