import {TableProps} from 'platform/components';
import {HStack, Text, Box} from 'platform/foundation';
import {currencies, formatCurrencySymbol} from 'platform/locale';

import {i18n} from '@price-report/shared';

import {CurrencyCodeType} from 'shared';

export function getTableColumns(currency: string): TableProps['columns'] {
  return [
    {
      element: (
        <Box padding={1} paddingLeft={6}>
          <HStack height={6} align="center">
            <Text size="xxSmall" color="secondary">
              {i18n.t('entity.drillDown.labels.specification')}
            </Text>
          </HStack>
        </Box>
      ),
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.soldWithUnit')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.stockWithUnit')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.soldPriceWithCurrency', {
                currency: formatCurrencySymbol(currencies, currency as CurrencyCodeType),
              })}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.stockPriceWithCurrency', {
                currency: formatCurrencySymbol(currencies, currency as CurrencyCodeType),
              })}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.soldMileage')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.stockMileage')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
    {
      element: (
        <Box padding={1}>
          <HStack height={6} align="center" justify="flex-end">
            <Text size="xxSmall" color="secondary" align="right">
              {i18n.t('entity.drillDown.labels.gap')}
            </Text>
          </HStack>
        </Box>
      ),
      width: 32,
    },
  ];
}
