import {Parameters} from 'platform/components';
import {Align, Display, HStack, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {VehicleLogo} from '../../../components/VehicleLogo/VehicleLogo';
import {CatalogueType} from '../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../hooks/useVehicleTranslatedInfo';

interface VehicleHeaderProps extends TestIdProps {
  priceReport: PriceReportType;
  catalogue: CatalogueType;
}

export function VehicleHeader(props: VehicleHeaderProps) {
  const vehicle = props.priceReport.vehicle;

  const formatNumber = useFormatNumber();

  const {data} = useVehicleTranslatedInfo(props.priceReport.vehicle);

  const parameters: Array<string | Nullish> = [
    vehicle.firstRegistrationOnYear.toString(),
    isNotNil(vehicle.mileage)
      ? `${formatNumber(vehicle.mileage)} ${i18n.t('general.metric.km')}`
      : null,
    isNotNil(vehicle.power)
      ? `${formatNumber(vehicle.power)} ${i18n.t('general.metric.kW')}`
      : null,
    props.catalogue.getTransmission(vehicle.transmission),
    props.catalogue.getFuelType(vehicle.fuelType),
    props.catalogue.getDrive(vehicle.drive),
    props.catalogue.getCarStyle(vehicle.bodyStyle),
  ].filter(isNotNilOrEmpty);

  return (
    <VStack align="center" spacing={4}>
      <HStack wrap spacing={4} align="center" justify="center">
        <VehicleLogo
          vehicle={props.priceReport.vehicle}
          height={14}
          width={14}
          color="white"
          data-testid={suffixTestId('vehicleLogo', props)}
        />
        <Align center>
          <Display size={1} color="white">{`${data.make} ${data.model}`}</Display>
        </Align>
      </HStack>
      <Align center>
        <Parameters parameters={parameters} color="white" size="base" justify="center" />
      </Align>
    </VStack>
  );
}
