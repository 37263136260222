import {ImageProps, Image} from 'platform/foundation';
import {match} from 'ts-pattern';

interface AdServerLogoProps {
  market: string;
  maxHeight: ImageProps['maxHeight'];
}

export function AdServerLogo(props: AdServerLogoProps) {
  const logoName = match(props.market)
    .with('CZE', () => 's-auto')
    .with('DEU', () => 'mobile-de')
    .with('POL', () => 'otomoto')
    .with('AUT', () => 'autoscout')
    .with('ITA', () => 'autoscout')
    .with('SVK', () => 'autobazar-eu')
    .with('FRA', () => 'la-centrale')
    .with('SWE', () => 'blocket')
    .with('ROU', () => 'autovit')
    .with('BEL', () => 'autoscout')
    .otherwise(() => 'mobile-de');

  return (
    <Image
      src={`/assets/images/ad-server-logo/${logoName}.svg`}
      maxHeight={props.maxHeight}
      fit="contain"
      position="left"
    />
  );
}
