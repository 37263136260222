import {format} from 'date-fns';
import {Attributes, AttributesRow, Card} from 'platform/components';
import {Align, Heading, HStack, Space, Text} from 'platform/foundation';
import {NumberFormat, useFormatNumber} from 'platform/locale';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {buildArray, parseDate, suffixTestId, TestIdProps} from 'shared';

import {CatalogueType} from '../../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../../hooks/useVehicleTranslatedInfo';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {VehicleBodyColorDot} from './components/VehicleBodyColorDot';

interface VehicleParametersCardProps extends TestIdProps {
  priceReport: PriceReportType;
  catalogue: CatalogueType;
}

export function VehicleParametersCard(props: VehicleParametersCardProps) {
  const {isPrint} = useIsPrintForced();
  const formatNumber = useFormatNumber();

  const {data} = useVehicleTranslatedInfo(props.priceReport.vehicle);

  const vehicle = props.priceReport.vehicle;

  const firstRegistration = isNotNil(vehicle.firstRegistrationOn)
    ? format(parseDate(vehicle.firstRegistrationOn), 'MM/yyyy')
    : vehicle.firstRegistrationOnYear.toString();

  const bodyColor = props.catalogue.getBodyColorHEX(vehicle.exteriorColor);

  const attributes = buildArray<AttributesRow>()
    .when(isNotNilOrEmpty(vehicle.vin), {
      label: i18n.t('entity.overviewParameters.labels.vin'),
      content: (
        <Text size="xSmall" alternative>
          {vehicle.vin}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.make'),
      content: (
        <Text size="xSmall" alternative>
          {data.make}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.model'),
      content: (
        <Text size="xSmall" alternative>
          {data.model}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.bodyStyle'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getCarStyle(vehicle.bodyStyle)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.exteriorColor), {
      label: i18n.t('entity.overviewParameters.labels.exteriorColor'),
      content: (
        <HStack spacing={2} align="center">
          <Text size="xSmall" alternative>
            {props.catalogue.getBodyColor(vehicle.exteriorColor)}
          </Text>
          {isNotNil(bodyColor) && <VehicleBodyColorDot $backgroundColor={bodyColor} />}
        </HStack>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.exteriorColorType), {
      label: i18n.t('entity.overviewParameters.labels.exteriorColorType'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getColorType(vehicle.exteriorColorType)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.doorCountCategory), {
      label: i18n.t('entity.overviewParameters.labels.doorCount'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getDoorCount(vehicle.doorCountCategory)}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.transmission'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getTransmission(vehicle.transmission)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.seatCount), {
      label: i18n.t('entity.overviewParameters.labels.seatCount'),
      content: (
        <Text size="xSmall" alternative>
          {vehicle.seatCount}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.power'),
      content: (
        <Text size="xSmall" alternative>{`${formatNumber(vehicle.power)} ${i18n.t(
          'general.metric.kW'
        )}`}</Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.drive'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getDrive(vehicle.drive)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.gearCount), {
      label: i18n.t('entity.overviewParameters.labels.gearCount'),
      content: (
        <Text size="xSmall" alternative>
          {vehicle.gearCount}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.engineVolume), {
      label: i18n.t('entity.overviewParameters.labels.engineVolume'),
      content: (
        <Text size="xSmall" alternative>
          <NumberFormat number={vehicle.engineVolume} decimals={0} />
          &nbsp;
          {i18n.t('general.metric.ccm')}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.fuelType), {
      label: i18n.t('entity.overviewParameters.labels.fuelType'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getFuelType(vehicle.fuelType)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.carbonDioxideEmission), {
      label: i18n.t('entity.overviewParameters.labels.carbonDioxideEmission'),
      content: (
        <Text size="xSmall" alternative>
          <NumberFormat number={vehicle.carbonDioxideEmission} decimals={0} />
          &nbsp;
          {i18n.t('general.metric.gPerKm')}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.emissionClass), {
      label: i18n.t('entity.overviewParameters.labels.emissionClass'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getEmissionClass(vehicle.emissionClass)}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.operatingWeight), {
      label: i18n.t('entity.overviewParameters.labels.weight'),
      content: (
        <Text size="xSmall" alternative>
          <NumberFormat number={vehicle.operatingWeight} />
          &nbsp;
          {i18n.t('general.metric.kg')}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.mileage'),
      content: (
        <Text size="xSmall" alternative>{`${formatNumber(vehicle.mileage)} ${i18n.t(
          'general.metric.km'
        )}`}</Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.firstRegistration'),
      content: (
        <Text size="xSmall" alternative>
          {firstRegistration}
        </Text>
      ),
    })
    .when(isNotNilOrEmpty(vehicle.interiorMaterial), {
      label: i18n.t('entity.overviewParameters.labels.interiorMaterial'),
      content: (
        <Text size="xSmall" alternative>
          {props.catalogue.getInteriorMaterial(vehicle.interiorMaterial)}
        </Text>
      ),
    })
    .add({
      label: i18n.t('entity.overviewParameters.labels.isVatDeductible'),
      content: (
        <Text size="xSmall" alternative>
          {vehicle.isVatDeductible ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}
        </Text>
      ),
    });

  return (
    <Card variant={isPrint ? 'inlineWhite' : undefined}>
      <Space vertical={2} />
      <Align center>
        <Heading size={2}>{i18n.t('entity.overviewParameters.labels.vehicleParameters')}</Heading>
      </Align>
      <Space vertical={6} />
      <Attributes rows={attributes} data-testid={suffixTestId('attributes', props)} />
    </Card>
  );
}
