import {FormControl, FormField} from 'platform/components';
import {Box, Heading, HStack, Show, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {getYearOptions, i18n} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {DRIVE_4X2} from '../constants/drive4x2';
import {DRIVE_4X4} from '../constants/drive4x4';
import {MAX_SEAT_COUNT} from '../constants/maxSeatCount';
import {MIN_FIRST_REGISTRATION_YEAR_OPTIONS} from '../constants/minFirstRegistrationYear';
import {WITHOUT_VAT} from '../constants/withoutVAT';
import {WITH_VAT} from '../constants/withVAT';
import {VehicleFormType} from '../types/VehicleFormType';
import {MakeModelFields} from './MakeModelFields';
import {VinParser} from './VinParser';

interface VehicleFieldsProps extends TestIdProps {
  count: number | Nullish;
  soldCount: number | Nullish;
  control: FormControl<VehicleFormType>;
  formApi: UseFormReturn<VehicleFormType>;
}

const seatCountOptions = Array.from(Array(MAX_SEAT_COUNT)).map((_, index) => ({
  value: `${index + 1}`,
  label: `${index + 1}`,
}));

export function VehicleFields(props: VehicleFieldsProps) {
  const {
    fuelOptions,
    styleOptions,
    transmissionOptions,
    colorOptions,
    doorOptions,
    interiorMaterialOptions,
  } = useCatalogue();

  const firstRegistrationYearOptions = getYearOptions(
    MIN_FIRST_REGISTRATION_YEAR_OPTIONS
  ).reverse();

  return (
    <VStack spacing={6}>
      <Heading size={2}>{i18n.t('page.create.labels.vehicleSpecification')}</Heading>
      <VinParser
        control={props.control}
        formApi={props.formApi}
        data-testid={suffixTestId('vinParser', props)}
      />
      <MakeModelFields
        control={props.control}
        formApi={props.formApi}
        data-testid={suffixTestId('makeModelFields', props)}
      />
      <HStack spacing={6}>
        <Box flex={1}>
          <FormField
            control={props.control}
            type="choice"
            name="firstRegistrationOnYear"
            label={i18n.t('page.create.labels.firstRegistrationOnYear')}
            isRequired
            options={firstRegistrationYearOptions}
            data-testid={suffixTestId('form-firstRegistrationOnYear', props)}
          />
        </Box>
        <Show onDesktop onNotebook>
          <Space fillAvailable />
        </Show>
      </HStack>
      <FormField
        control={props.control}
        type="chips"
        name="transmission"
        label={i18n.t('page.create.labels.transmission')}
        isRequired
        options={transmissionOptions ?? []}
        data-testid={suffixTestId('form-transmission', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="fuelType"
        label={i18n.t('page.create.labels.fuelType')}
        isRequired
        options={fuelOptions ?? []}
        data-testid={suffixTestId('form-fuelType', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="bodyStyle"
        label={i18n.t('page.create.labels.bodyStyle')}
        isRequired
        options={styleOptions ?? []}
        data-testid={suffixTestId('form-bodyStyle', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="drive"
        label={i18n.t('page.create.labels.drive4x4')}
        isRequired
        options={[
          {label: i18n.t('page.create.labels.yes'), value: DRIVE_4X4},
          {label: i18n.t('page.create.labels.no'), value: DRIVE_4X2},
        ]}
        data-testid={suffixTestId('form-drive', props)}
      />
      <HStack spacing={6}>
        <Box flex={1}>
          <FormField
            control={props.control}
            type="number"
            name="power"
            label={`${i18n.t('page.create.labels.power')} (${i18n.t('general.metric.kW')})`}
            isRequired
            data-testid={suffixTestId('form-power', props)}
          />
        </Box>
        <Show onDesktop onNotebook>
          <Space fillAvailable />
        </Show>
      </HStack>
      <FormField
        control={props.control}
        type="chips"
        name="exteriorColor"
        label={i18n.t('page.create.labels.exteriorColor')}
        options={colorOptions ?? []}
        data-testid={suffixTestId('form-exteriorColor', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="interiorMaterial"
        label={i18n.t('page.create.labels.interiorMaterial')}
        options={interiorMaterialOptions ?? []}
        data-testid={suffixTestId('form-interiorMaterial', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="isVatDeductible"
        label={i18n.t('page.create.labels.vatDeductible')}
        options={[
          {label: i18n.t('page.create.labels.yes'), value: WITH_VAT},
          {label: i18n.t('page.create.labels.no'), value: WITHOUT_VAT},
        ]}
        data-testid={suffixTestId('form-isVatDeductible', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="doorCount"
        label={i18n.t('page.create.labels.doorCount')}
        options={doorOptions ?? []}
        data-testid={suffixTestId('form-doorCount', props)}
      />
      <FormField
        control={props.control}
        type="chips"
        name="seatCount"
        label={i18n.t('page.create.labels.seatCount')}
        options={seatCountOptions}
        data-testid={suffixTestId('form-seatCount', props)}
      />
      <HStack spacing={6}>
        <Box flex={1}>
          <FormField
            control={props.control}
            type="number"
            name="mileage"
            label={`${i18n.t('page.create.labels.mileage')} (${i18n.t('general.metric.km')})`}
            isRequired
            data-testid={suffixTestId('form-mileage', props)}
          />
        </Box>
        <Show onDesktop onNotebook>
          <Space fillAvailable />
        </Show>
      </HStack>
    </VStack>
  );
}
