import {isNil} from 'ramda';

import {FALLBACK_LANGUAGE, i18n, VehicleType} from '@price-report/shared';

import {Nullish} from 'shared';

import {useGetMakeModelQuery} from '../store/catalogueApi';

export function useVehicleTranslatedInfo(
  vehicle: Pick<VehicleType, 'make' | 'modelFamily' | 'vehicleType'> | Nullish,
  hasLogoUrl?: boolean
) {
  const vehicleNotDefined = isNil(vehicle?.make) || isNil(vehicle?.vehicleType);

  const {data, isLoading} = useGetMakeModelQuery(
    {
      make: vehicle?.make!,
      vehicleType: vehicle?.vehicleType!,
      lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
      logoUrl: hasLogoUrl,
    },
    {skip: vehicleNotDefined}
  );

  const make = data?.[0];
  const vehicleData = {
    make: make?.default_label,
    model: make?.models?.find((item) => item.model === vehicle?.modelFamily)?.default_label ?? '',
    logoUrl: make?.logo_url_2x,
    whiteLogoUrl: make?.white_logo_url_2x,
    blackLogoUrl: make?.black_logo_url_2x,
  };

  const undefinedVehicleData = {
    make: '',
    model: '',
    logoUrl: undefined,
    whiteLogoUrl: undefined,
    blackLogoUrl: undefined,
  };

  return {
    isLoading,
    data: vehicleNotDefined ? undefinedVehicleData : vehicleData,
  };
}
