import {Pagination as PlatformPagination} from 'platform/components';
import {HStack, Show, Text, useDevice} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

export interface PaginationProps extends TestIdProps {
  results: number;
  page: number;
  pagesQuantity: number;
  onPageChange: (value: number) => void;
}

export function Pagination(props: PaginationProps) {
  const device = useDevice();

  return (
    <HStack align="center" spacing={2}>
      <HStack align="center">
        <Text
          inline
          color="primary"
          size="xSmall"
          alternative
          data-testid={suffixTestId('resultCount', props)}
        >
          {props.results}
        </Text>
        &nbsp;
        <Text inline color="secondary" size="xSmall">
          {i18n.t('entity.header.labels.results', {count: props.results})}
        </Text>
      </HStack>
      <Show when={props.pagesQuantity > 1}>
        <PlatformPagination
          page={props.page}
          pagesQuantity={props.pagesQuantity}
          onPageChange={props.onPageChange}
          showPrevButton
          showNextButton
          variant={device !== 'desktop' ? 'text-only' : 'button'}
          data-testid={suffixTestId('pagination', props)}
        />
      </Show>
    </HStack>
  );
}
