import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import {isNotNil} from 'ramda';

import {App} from './App';
import {gtmInit} from './utils/gtmInit';
import {initSentry} from './utils/sentry';

initSentry();
gtmInit();

// Disable auto-zoom on iPhones
const viewport = document.querySelector('[name=viewport]');
if (navigator.userAgent.includes('iPhone') && isNotNil(viewport)) {
  viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
