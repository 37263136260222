import {Button, Dialog, IconButton} from 'platform/components';
import {Box, Heading, HStack, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {i18n} from '@price-report/shared';

import {suffixTestId} from 'shared';

import {useFilter} from '../../../hooks/FilterContext';
import {Filter, FilterProps} from '../../Filter/Filter';

interface FilterModalProps extends FilterProps {
  onReset: () => void;
}

export function FilterModal(props: FilterModalProps) {
  const context = useFilter();
  const theme = useTheme();

  return (
    <Dialog
      isOpen={context.isOpenMobileFilter}
      onClose={context.closeMobileFilter}
      size="small"
      disableBodyPadding
      data-testid={suffixTestId('modal', props)}
    >
      <Box
        padding={4}
        position="sticky"
        top={0}
        backgroundColor="general.white"
        borderRadius={theme.components.Card.borderRadius}
        zIndex="HEADER"
        data-testid={suffixTestId('modalHeader', props)}
      >
        <HStack spacing={4} justify="space-between" align="center">
          <Heading size={3}>{i18n.t('page.advertisiments.filter')}</Heading>
          <IconButton
            icon="navigation/close"
            onClick={context.closeMobileFilter}
            priority="secondary"
            data-testid={suffixTestId('closeButton', props)}
          />
        </HStack>
      </Box>
      <Box padding={4}>
        <Filter
          priceReport={props.priceReport}
          formControl={props.formControl}
          formApi={props.formApi}
          data-testid={suffixTestId('filter', props)}
        />
      </Box>
      <Box
        padding={4}
        position="sticky"
        bottom={0}
        backgroundColor="general.white"
        borderRadius={theme.components.Card.borderRadius}
        data-testid={suffixTestId('modalFooter', props)}
      >
        <VStack spacing={4}>
          <Button
            onClick={props.onReset}
            variant="secondary"
            leftIcon="navigation/refresh"
            title={i18n.t('entity.filterForm.restore')}
            isFullWidth
            data-testid={suffixTestId('resetButton', props)}
          />
          <Button
            onClick={context.closeMobileFilter}
            variant="primary"
            leftIcon="content/filter_list"
            title={i18n.t('entity.filterForm.filter')}
            isFullWidth
            data-testid={suffixTestId('confirmButton', props)}
          />
        </VStack>
      </Box>
    </Dialog>
  );
}
