import {Choice, Segment} from 'platform/components';
import {Box, useDevice} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {ChartVariant, chartVariantOptions} from '../utils/chartVariant';

interface ChartTypeSwitchProps extends TestIdProps {
  chartVariant: ChartVariant;
  defaultChartType: ChartVariant;
  setChartVariant: (value: ChartVariant) => void;
}

export function ChartTypeSwitch(props: ChartTypeSwitchProps) {
  const device = useDevice();
  const isChartTypeSelectCompact = device !== 'desktop';

  return isChartTypeSelectCompact ? (
    <Box width={['100%', '100%', 'auto', 'auto']}>
      <Choice
        value={props.chartVariant}
        options={chartVariantOptions}
        onChange={(value) =>
          props.setChartVariant(isNotNil(value) ? value : props.defaultChartType)
        }
        isNotClearable
        horizontalMenuPlacement="right"
        data-testid={suffixTestId('choice', props)}
      />
    </Box>
  ) : (
    <Segment
      value={props.chartVariant}
      options={chartVariantOptions}
      onChange={props.setChartVariant}
      data-testid={suffixTestId('segment', props)}
    />
  );
}
