import {Card, Rank, Separator, Trend} from 'platform/components';
import {
  Align,
  Box,
  Center,
  CSSDimension,
  getSize,
  Heading,
  Hide,
  HStack,
  Icon,
  Space,
  Stack,
  Text,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';
import {match} from 'ts-pattern';

import {Trans} from 'react-i18next';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {useCatalogue} from '../../../../hooks/useCatalogue';
import {ResponsiveGrid} from '../../components/ResponsiveGrid';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {getDiffLabel} from '../../utils/getDiffLabel';
import {getValueOrEmptyString} from '../../utils/getValueOrEmptyString';
import {AvailableFeatures} from './components/AvailableFeatures';
import {MissingFeatures} from './components/MissingFeatures';
import {getStatisticVariant} from './utils/getStatisticVariant';

const PERCENTAGE_MULTIPLIER = 100;

interface StatisticsCardFeaturesProps extends TestIdProps {
  market?: MarketType;
  priceReport: PriceReportType;
}

export function StatisticsCardFeatureLevel(props: StatisticsCardFeaturesProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const formatPercentage = useFormatPercentage();
  const {getFeatureLabel, hasFeatureTranslation} = useCatalogue();

  const variant = getStatisticVariant(
    props.priceReport.featuresLevel ?? 0,
    props.market?.sold?.averageFeatureLevel ?? 0
  );

  const alertTranslationKey = match(variant)
    .with('success', () => 'entity.overviewStatistics.labels.featuresLevelAlertSuccess')
    .with('neutral', () => 'entity.overviewStatistics.labels.featuresLevelAlertNeutral')
    .with('warning', () => 'entity.overviewStatistics.labels.featuresLevelAlertWarning')
    .exhaustive();

  const messageColor: ThemeColorPath =
    variant === 'warning' ? 'palettes.yellow.20.100' : 'palettes.green.20.100';

  const isSomeVehicleSold = (props.market?.sold?.similarVehicle ?? 0) > 0;
  const isSomeVehicleInStock = (props.market?.stock?.similarVehicle ?? 0) > 0;

  const featuresLevel = props.priceReport.featuresLevel;
  const featureLevelSold = isSomeVehicleSold ? props.market?.sold?.averageFeatureLevel : undefined;
  const featureLevelStock = isSomeVehicleInStock
    ? props.market?.stock?.averageFeatureLevel
    : undefined;

  const soldDifference =
    isNil(featuresLevel) || isNil(featureLevelSold) || !isSomeVehicleSold
      ? undefined
      : featuresLevel - featureLevelSold;

  const soldDifferenceAbsolute = isNil(soldDifference) ? undefined : Math.abs(soldDifference);

  const stockDifference =
    isNil(featuresLevel) || isNil(featureLevelStock) || !isSomeVehicleInStock
      ? undefined
      : featuresLevel - featureLevelStock;

  const mapFeatureKeysToTranslations = (features?: string[]) =>
    isNotNil(features) ? features.filter(hasFeatureTranslation).map(getFeatureLabel) : undefined;

  const availableFeatures = mapFeatureKeysToTranslations(
    props.market?.recommendedFeatures?.available
  );
  const missingFeatures = mapFeatureKeysToTranslations(props.market?.recommendedFeatures?.missing);

  const featuresBoxWidth: CSSDimension = getResponsiveValue(
    '100%',
    '100%',
    `calc(50% - ${getSize(4)})` as const,
    `calc(50% - ${getSize(4)})` as const,
    `calc(50% - ${getSize(4)})` as const
  );

  return (
    <AvoidBreakInside>
      <Card variant={isPrint ? 'inlineWhite' : undefined}>
        <Box padding={2}>
          <VStack spacing={6}>
            <Align center>
              <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.featuresTitle')}</Heading>
            </Align>
            <Text align="center" size="small" color="secondary">
              <Trans
                i18nKey="entity.overviewStatistics.labels.featuresDesciption"
                components={{bold: <Text alternative inline size="small" />}}
              />
            </Text>
            <ResponsiveGrid>
              <Box>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.featureLevelYourVehicle')}
                  </Text>
                </Align>
                <Space vertical={2} />
                <Center spacing={2}>
                  <Heading size={2} data-testid={suffixTestId('vehicleFeatureLevel', props)}>
                    {getValueOrEmptyString(featuresLevel, (value) =>
                      formatPercentage(value, 'down')
                    )}
                  </Heading>
                  {isNotNil(featuresLevel) && (
                    <Rank
                      percentage={featuresLevel * PERCENTAGE_MULTIPLIER}
                      data-testid={suffixTestId('featureLevelRank', props)}
                    />
                  )}
                </Center>
              </Box>
              <Box>
                <VStack spacing={2} align="center">
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.featureLevelSoldCars')}
                  </Text>
                  <Center spacing={2}>
                    <Heading size={2} data-testid={suffixTestId('soldAverageFeatureLevel', props)}>
                      {getValueOrEmptyString(featureLevelSold, (value) =>
                        formatPercentage(value, 'down')
                      )}
                    </Heading>
                    {isNotNil(featureLevelSold) && (
                      <Rank
                        percentage={featureLevelSold * PERCENTAGE_MULTIPLIER}
                        data-testid={suffixTestId('soldFeatureLevelRank', props)}
                      />
                    )}
                  </Center>
                  {isNotNil(soldDifference) && (
                    <Trend
                      label={getDiffLabel(soldDifference, (value: number) =>
                        formatPercentage(value, 'nearest')
                      )}
                      isSubtle
                      isSmall
                      isPositive={soldDifference > 0}
                      isNegative={soldDifference < 0}
                      data-testid={suffixTestId('soldFeatureLevelTrend', props)}
                    />
                  )}
                </VStack>
              </Box>
              <VStack spacing={2} align="center">
                <Text size="xSmall" color="secondary">
                  {i18n.t('entity.overviewStatistics.labels.featureLevelStockCars')}
                </Text>
                <Center spacing={2}>
                  <Heading size={2} data-testid={suffixTestId('stockAverageFeatureLevel', props)}>
                    {getValueOrEmptyString(featureLevelStock, (value) =>
                      formatPercentage(value, 'down')
                    )}
                  </Heading>
                  {isNotNil(featureLevelStock) && (
                    <Rank
                      percentage={featureLevelStock * PERCENTAGE_MULTIPLIER}
                      data-testid={suffixTestId('stockFeatureLevelRank', props)}
                    />
                  )}
                </Center>
                {isNotNil(stockDifference) && (
                  <Trend
                    label={getDiffLabel(stockDifference, (value: number) =>
                      formatPercentage(value, 'nearest')
                    )}
                    isSubtle
                    isSmall
                    isPositive={stockDifference > 0}
                    isNegative={stockDifference < 0}
                    data-testid={suffixTestId('stockFeatureLevelTrend', props)}
                  />
                )}
              </VStack>
            </ResponsiveGrid>
            <Hide
              when={
                isNil(props.priceReport.featuresLevel) ||
                isNil(props.market?.sold?.averageFeatureLevel) ||
                !isSomeVehicleSold
              }
            >
              <Box backgroundColor={messageColor} borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Hide when={variant === 'neutral'}>
                    <Icon
                      value={variant === 'success' ? 'action/trending_up' : 'action/trending_down'}
                      color={variant === 'success' ? 'severity.success' : 'severity.warning'}
                      size={7}
                    />
                  </Hide>
                  <Text
                    size="small"
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  >
                    <Trans
                      i18nKey={alertTranslationKey}
                      values={{
                        percentage: getValueOrEmptyString(soldDifferenceAbsolute, (value) =>
                          formatPercentage(value, 'nearest')
                        ),
                      }}
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </Hide>
            <Hide
              when={
                isNilOrEmpty(props.market?.recommendedFeatures?.available) &&
                isNilOrEmpty(props.market?.recommendedFeatures?.missing)
              }
            >
              <Stack direction={getResponsiveValue('column', 'column', 'row', 'row', 'row')}>
                <Box flex={1} maxWidth={featuresBoxWidth}>
                  <AvailableFeatures
                    features={availableFeatures}
                    data-testid={suffixTestId('availableFeatures', props)}
                  />
                </Box>
                <Separator
                  orientation={getResponsiveValue(
                    'horizontal',
                    'horizontal',
                    'vertical',
                    'vertical',
                    'vertical'
                  )}
                  spacing={getResponsiveValue(6, 6, 4, 4, 4)}
                />
                <Box flex={1} maxWidth={featuresBoxWidth}>
                  <MissingFeatures
                    features={missingFeatures}
                    data-testid={suffixTestId('missingFeatures', props)}
                  />
                </Box>
              </Stack>
            </Hide>
          </VStack>
        </Box>
      </Card>
    </AvoidBreakInside>
  );
}
