import {Flag, FlagProps} from 'platform/components';
import {VStack} from 'platform/foundation';
import {Label, ReferenceLine} from 'recharts';

import {isTrue} from 'ramda-adjunct';

interface ReferenceLineXProps {
  value: number;
  formatter: (value: number) => string;
  flagColor: FlagProps['colorScheme'];
  isFlagSubtle?: FlagProps['isSubtle'];
  flagHorizontalAlign?: 'left' | 'right';
  flagVerticalAlign?: 'top' | 'bottom';
  lineColor: string;
  isDashed?: boolean;
  isLabelVisible?: boolean;
}

const LABEL_WIDTH = 100;

export function renderReferenceLineX(props: ReferenceLineXProps) {
  const offsetX = props.flagHorizontalAlign === 'left' ? -LABEL_WIDTH - 3 : 3;
  const offsetY = props.flagVerticalAlign === 'bottom' ? -3 : 3;
  return (
    <ReferenceLine
      x={props.value}
      stroke={props.lineColor}
      pointerEvents="none"
      strokeDasharray={props.isDashed ? 3 : undefined}
    >
      {isTrue(props.isLabelVisible) && (
        <Label
          pointerEvents="none"
          content={(p) => {
            const cartesianViewBox = p.viewBox && 'x' in p.viewBox ? p.viewBox : null;
            return (
              <foreignObject
                x={(cartesianViewBox?.x ?? 0) + offsetX}
                y={(cartesianViewBox?.y ?? 0) + offsetY}
                height={cartesianViewBox?.height}
                width={LABEL_WIDTH}
                pointerEvents="none"
              >
                <VStack
                  height="100%"
                  align={props.flagHorizontalAlign === 'left' ? 'flex-end' : 'flex-start'}
                  justify={props.flagVerticalAlign === 'bottom' ? 'flex-end' : undefined}
                >
                  <Flag
                    label={props.formatter?.(props.value)}
                    colorScheme={props.flagColor}
                    isSubtle={props.isFlagSubtle}
                    size="small"
                  />
                </VStack>
              </foreignObject>
            );
          }}
        />
      )}
    </ReferenceLine>
  );
}
