import {differenceInDays, startOfDay, startOfToday} from 'date-fns';
import {getName} from 'i18n-iso-countries';
import {Dropdown} from 'platform/components';
import {Box, Hide, HStack, Icon, Show, Text, useDevice, VStack} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';

import {FALLBACK_LANGUAGE, i18n} from '@price-report/shared';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {useIsPrintForced} from '../../../../../pages/Overview/hooks/useIsPrintForced';

interface ReportInfoProps extends RequiredTestIdProps {
  created?: string;
  market?: string | null;
  couponNumber?: string | null;
}

export function ReportInfo(props: ReportInfoProps) {
  const device = useDevice();
  const {isPrint} = useIsPrintForced();
  const isDesktop = device === 'desktop';

  const createdDate = parseDate(props.created);
  const dateString = createdDate?.toLocaleDateString(i18n?.resolvedLanguage);
  const differenceFromNowAndCreatedDateInDays = differenceInDays(
    startOfToday(),
    startOfDay(createdDate)
  );

  const marketTitle = isNotNil(props.market)
    ? (getName(props.market, i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE) ??
      i18n.t(`page.overview.market.${props.market}`, {defaultValue: props.market}))
    : undefined;

  return (
    <HStack spacing={6}>
      <Show when={isNotNil(dateString) && (isDesktop || isPrint)}>
        <Text color="white" size="xSmall">
          {i18n.t('entity.priceReport.created')} {dateString}
          <Hide when={isPrint}>
            {` (${i18n.t('entity.header.labels.nDaysAgo', {
              count: differenceFromNowAndCreatedDateInDays,
            })})`}
          </Hide>
        </Text>
      </Show>

      <Show when={isNotNil(marketTitle) && (isDesktop || isPrint)}>
        <Text color="white" size="xSmall">
          {i18n.t('entity.priceReport.market')}: {marketTitle}
        </Text>
      </Show>

      <Show when={isNotNil(props.couponNumber) && isPrint}>
        <Text color="white" size="xSmall">
          {i18n.t('entity.priceReport.couponNumber')}: {props.couponNumber}
        </Text>
      </Show>

      <Hide when={(isNil(props.couponNumber) && isDesktop) || isPrint}>
        <Dropdown
          dropdownControl={<Icon color="palettes.white.10.100" value="action/info" size={4} />}
          data-testid={suffixTestId('reportInfoDropdown', props)}
        >
          <Box padding={2}>
            <VStack spacing={2}>
              <Hide onDesktop when={isNil(dateString)}>
                <Text size="xSmall">{`${i18n.t('entity.priceReport.created')} ${dateString}`}</Text>
              </Hide>
              <Hide onDesktop when={isNil(marketTitle)}>
                <Text size="xSmall">
                  {i18n.t('entity.priceReport.market')}: {marketTitle}
                </Text>
              </Hide>
              <Hide when={isNil(props.couponNumber)}>
                <Text size="xSmall">
                  {i18n.t('entity.priceReport.couponNumber')}: {props.couponNumber}
                </Text>
              </Hide>
            </VStack>
          </Box>
        </Dropdown>
      </Hide>
    </HStack>
  );
}
