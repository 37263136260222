import {ImageFit} from 'platform/foundation';

export const makeStorageUrl = (url: string, width: number, height: number, fit: ImageFit) => {
  // if URL is aplha get endpoint, replace it with aplha resize endpoint
  // aplha dev server URL is the same as prod URL
  const aplhaResizeEndpointUrl = url.replace(
    'storage.prod.alpha-analytics.dev/get/',
    'storage.prod.alpha-analytics.dev/resize/'
  );
  const storageUrl = new URL(aplhaResizeEndpointUrl, window.location.href);
  storageUrl.searchParams.set('width', width.toString());
  storageUrl.searchParams.set('height', height.toString());
  storageUrl.searchParams.set('fit', storageFitMap[fit]);

  return storageUrl.toString();
};

const storageFitMap = {
  contain: 'inside',
  'scale-down': 'inside',
  cover: 'cover',
} as const;
