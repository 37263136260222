import {createGlobalStyle} from 'styled-components';
import {Normalize} from 'styled-normalize';

// eslint-disable-next-line eag/no-css-property
const BasicStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

  @media print {
    @page {
      margin: 10mm 0;
      size: 297mm 421mm;
    }

    @page:first {
      margin-top: 0;
    }
  }
`;

export const GlobalStyles = () => (
  <>
    <Normalize />
    <BasicStyles />
  </>
);
