import {Card, DataStatus, Separator} from 'platform/components';
import {
  Box,
  getSize,
  getValueByDevice,
  Hide,
  Integer,
  Show,
  Space,
  useDevice,
  VStack,
} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {DesktopPagination} from '../../components/Pagination/DesktopPagination';
import {MobilePaginationWithFilterBar} from '../../components/Pagination/MobilePaginationWithFilterBar';
import {VehicleInfo} from '../../components/VehicleInfo/VehicleInfo';
import {useFilter} from '../../hooks/FilterContext';
import {useCatalogue} from '../../hooks/useCatalogue';
import {useHeaderHeight} from '../../hooks/useHeaderHeight';
import {usePriceReport} from '../../hooks/usePriceReport';
import {useGetStatisticsV2Query} from '../../store/priceReportApi';
import {StatisticsChart} from './components/Chart/StatisticsChart';
import {addMyVehicleToAllCars} from './utils/addMyVehicleToCars';

const ITEMS_PER_PAGE = 1000;

export function StatisticsPage(props: TestIdProps) {
  const device = useDevice();
  const headerHeight = useHeaderHeight();
  const {filter, errors: filterErrors} = useFilter();

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const {data: priceReport} = usePriceReport();
  const catalogue = useCatalogue();

  const {data, isLoading, isFetching, isError} = useGetStatisticsV2Query(
    {
      reportId: priceReport?.id ?? '',
      body: {
        ...filter,
        query_price: priceReport?.sellingPrice.recommended.value,
        offset: (page - 1) * ITEMS_PER_PAGE,
        size: ITEMS_PER_PAGE,
      },
    },
    {skip: !filter || !priceReport}
  );

  const currentPage = data?.current_page ?? 0;
  const pagesQuantity = data?.total_pages ?? 0;
  const results = data?.total_hits ?? 0;

  if (!priceReport) {
    return null;
  }

  // TODO T20-28291 it should not be error
  const isMetadaError = isError && filter?.hideStockCars && filter?.hideSoldCars;

  const cars = isMetadaError || filterErrors ? [] : (data?.cars ?? []);

  const carsWithMyVehicle = addMyVehicleToAllCars(
    cars,
    priceReport,
    data?.query_original_price_index ?? 0
  );

  const headerBorder = getValueByDevice(device, '1px', '1px', '0px', '0px');
  const filterBarHeight = getValueByDevice(device, 12, 12, 0, 0);
  const pagePaddings = 2 * 2;
  const cardPaddings = 2 * 4;

  return (
    <>
      <Helmet title={i18n.t('page.statistics.title')} />
      <MobilePaginationWithFilterBar
        page={currentPage}
        onPageChange={setPage}
        pagesQuantity={pagesQuantity}
        results={results}
        isLoading={isFetching || isLoading}
      />
      <Box padding={2}>
        <Card>
          <VStack
            minHeight={`calc(100vh - ${headerHeight}px - ${getSize(
              (filterBarHeight + pagePaddings + cardPaddings) as Integer
            )} - ${headerBorder})`}
          >
            <VehicleInfo catalogue={catalogue} vehicle={priceReport.vehicle}>
              <DesktopPagination
                page={currentPage}
                onPageChange={setPage}
                pagesQuantity={pagesQuantity}
                results={results}
                data-testid={suffixTestId('desktopPagination', props)}
              />
            </VehicleInfo>
            <Show onMobile onTablet>
              <Separator />
            </Show>
            <Hide onMobile onTablet>
              <Space vertical={4} />
            </Hide>
            <DataStatus isLoading={isLoading} isError={isError && !isMetadaError} grow={1}>
              <StatisticsChart
                priceReport={priceReport}
                data={carsWithMyVehicle}
                catalogue={catalogue}
                isLoading={isFetching}
                data-testid={suffixTestId('statisticsChart', props)}
              />
            </DataStatus>
          </VStack>
        </Card>
      </Box>
    </>
  );
}
