import {ThemeColorPath, Icon, Box, Center, HStack, Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

interface StepProps {
  text: string;
  index: number;
  value: number;
}

export function Step(props: StepProps) {
  const backgroundColor = match<[number, number], ThemeColorPath>([props.index, props.value])
    .when(
      ([index, value]) => index < value,
      () => 'severity.success'
    )
    .when(
      ([index, value]) => index === value,
      () => 'palettes.blue.20.100'
    )
    .otherwise(() => 'palettes.blue.60.100');

  return (
    <HStack>
      <Box height={6} width={6} backgroundColor={backgroundColor} borderRadius="circular">
        <Center height={6} width={6}>
          {props.index < props.value ? (
            <Icon value="navigation/check" size={4} color="general.white" />
          ) : (
            <Text color={props.value === props.index ? 'link' : 'white'} size="small" alternative>
              {props.index + 1}
            </Text>
          )}
        </Center>
      </Box>
      <Space horizontal={3} />
      <Text color="white" alternative>
        {props.text}
      </Text>
    </HStack>
  );
}
