import {Card, Form} from 'platform/components';
import {Box, Hide, HStack, Show} from 'platform/foundation';

import {useCallback, useMemo} from 'react';
import {Outlet} from 'react-router-dom';

import {isNil} from 'ramda';

import {FilterFormData, getDefaultValues, validateFilterValues} from '@price-report/shared';

import {useDebouncedCallback} from 'shared';

import {FilterContextType, FilterProvider} from '../../hooks/FilterContext';
import {useSelectedFilters} from '../../hooks/SelectedFiltersContext';
import {usePriceReport} from '../../hooks/usePriceReport';
import {Filter} from '../Filter/Filter';
import {formValuesToRequestValues} from '../Filter/utils/formValuesToRequestValues';
import {StickyContainer} from '../StickyContainer/StickyContainer';
import {FilterModal} from './components/FilterModal';

export function FilterLayout() {
  const {data: priceReport} = usePriceReport();
  const {selectedFilters, onSelectFilters} = useSelectedFilters();

  const changeHandler = useCallback(
    (context: FilterContextType, values: FilterFormData) => {
      const errors = validateFilterValues(values);
      context.setErrors(errors);

      if (isNil(errors)) {
        context.onFilterChange(formValuesToRequestValues(values));
        onSelectFilters(values);
      }
    },
    [onSelectFilters]
  );

  const debouncedChangeHandler = useDebouncedCallback(changeHandler, 500);

  const defaultValues = useMemo(
    () => getDefaultValues(priceReport?.vehicle),
    [priceReport?.vehicle]
  );

  if (!priceReport) {
    return null;
  }

  return (
    <FilterProvider initialFilter={formValuesToRequestValues(selectedFilters ?? defaultValues)}>
      {(context) => (
        <Form<FilterFormData>
          defaultValues={selectedFilters ?? defaultValues}
          onChange={(values) => debouncedChangeHandler(context, values)}
        >
          {(control, formApi) => {
            const onReset = () => {
              formApi.reset(defaultValues);
              context.onFilterChange(formValuesToRequestValues(defaultValues));
              context.setErrors(null);
              onSelectFilters(defaultValues);
            };
            return (
              <>
                <HStack>
                  <Hide onMobile onTablet>
                    <Box width={80} flexShrink={0} padding={2} paddingRight={0}>
                      <StickyContainer offset={2}>
                        <Card>
                          <Filter
                            priceReport={priceReport}
                            formControl={control}
                            formApi={formApi}
                            onReset={onReset}
                            data-testid="filter"
                          />
                        </Card>
                      </StickyContainer>
                    </Box>
                  </Hide>
                  <Box minWidth={0} flexGrow={1}>
                    <Outlet />
                  </Box>
                </HStack>
                <Show onMobile onTablet>
                  <FilterModal
                    priceReport={priceReport}
                    formControl={control}
                    formApi={formApi}
                    onReset={onReset}
                    data-testid="filterModal"
                  />
                </Show>
              </>
            );
          }}
        </Form>
      )}
    </FilterProvider>
  );
}
