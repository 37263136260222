import {isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {i18n} from '../i18n/i18n';
import {FiltersErrors} from '../types/api/api';
import {FilterFormData} from '../types/FilterFormData';

export const validateFilterValues = (values: FilterFormData): FiltersErrors | null => {
  let errors = {};
  if (isNotNil(values.priceGte) && values.priceGte < 0) {
    errors = {price: i18n.t('entity.filterForm.error.price.positive')};
  }
  if (isNotNil(values.priceLte) && values.priceLte < 0) {
    errors = {price: i18n.t('entity.filterForm.error.price.positive')};
  }
  if (isNotNil(values.priceGte) && isNotNil(values.priceLte) && values.priceGte > values.priceLte) {
    errors = {price: i18n.t('entity.filterForm.error.price.comparison')};
  }

  if (isNotNil(values.mileageGte) && values.mileageGte < 0) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.positive')};
  }
  if (isNotNil(values.mileageLte) && values.mileageLte < 0) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.positive')};
  }
  if (
    isNotNil(values.mileageGte) &&
    isNotNil(values.mileageLte) &&
    values.mileageGte > values.mileageLte
  ) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.comparison')};
  }

  if (isNotNil(values.powerGte) && values.powerGte < 0) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.positive')};
  }
  if (isNotNil(values.powerLte) && values.powerLte < 0) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.positive')};
  }
  if (isNotNil(values.powerGte) && isNotNil(values.powerLte) && values.powerGte > values.powerLte) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.comparison')};
  }

  return isNotEmpty(errors) ? errors : null;
};
