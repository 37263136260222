import {Checkbox, Flag, FormControlProps} from 'platform/components';
import {HStack, Stack} from 'platform/foundation';

import {append, without} from 'ramda';

import {i18n} from '@price-report/shared';

import {Availability} from '../types';
import {Flag as CustomFlag} from './Flag';

export interface ChartLegendProps extends FormControlProps<Availability[]> {
  onStockCount: number;
  soldIn30DaysCount: number;
  soldIn90DaysCount: number;
}

export function ChartLegend(props: ChartLegendProps) {
  const handleChange = (availability: Availability) => (value: boolean) => {
    if (value) {
      props.onChange?.(append(availability, props.value));
    } else {
      props.onChange?.(without([availability], props.value));
    }
  };

  return (
    <Stack direction={['column', 'row', 'row', 'row']} spacing={4} wrap>
      <HStack align="center" spacing={2}>
        <Checkbox value={props.value.includes('onStock')} onChange={handleChange('onStock')} />
        <Flag
          label={`${i18n.t('entity.charts.labels.onStock')} (${props.onStockCount})`}
          colorScheme="orange"
        />
      </HStack>
      <HStack align="center" spacing={2}>
        <Checkbox
          value={props.value.includes('soldIn30Days')}
          onChange={handleChange('soldIn30Days')}
        />
        <Flag
          label={`${i18n.t('entity.charts.labels.sold30')} (${props.soldIn30DaysCount})`}
          colorScheme="neutral"
        />
      </HStack>
      <HStack align="center" spacing={2}>
        <Checkbox
          value={props.value.includes('soldIn90Days')}
          onChange={handleChange('soldIn90Days')}
        />
        <CustomFlag
          label={`${i18n.t('entity.charts.labels.sold90')} (${props.soldIn90DaysCount})`}
          color="palettes.neutral.800.100"
          backgroundColor="palettes.neutral.40.100"
        />
      </HStack>
    </Stack>
  );
}
