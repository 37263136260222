import {sum} from 'ramda';

import {StatisticsChartItem} from '../types';

export function getAverageValueWithoutMyVehicle(
  key: 'x' | 'y',
  items: StatisticsChartItem[]
): number {
  const values = items.filter((item) => !item.car.isMyVehicle).map((item) => item[key]);

  return Math.round(sum(values) / values.length);
}
