import {RequestVehicle} from '@price-report/shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {WITH_VAT} from '../constants/withVAT';
import {VehicleFormType} from '../types/VehicleFormType';

export const useGetRequestVehicleFromFormData = () => {
  const {hasFeatureTranslation} = useCatalogue();

  return (formData: VehicleFormType): RequestVehicle => ({
    bodyStyle: formData.bodyStyle?.[0],
    drive: formData.drive?.[0],
    features: formData.features.filter(hasFeatureTranslation),
    firstRegistrationOnYear: formData.firstRegistrationOnYear,
    fuelType: formData.fuelType?.[0],
    isVatDeductible: formData.isVatDeductible?.[0] === WITH_VAT,
    make: formData.make,
    modelFamily: formData.modelFamily,
    mileage: formData.mileage,
    power: formData.power,
    transmission: formData.transmission?.[0],
    vin: formData.vin,
    exteriorColor: formData.exteriorColor?.[0],
    vehicleType: 'VEHICLETYPE_PASSENGER_CAR',
    seatCount: parseFloat(formData.seatCount?.[0]),
    doorCountCategory: formData.doorCount?.[0],
    interiorMaterial: formData.interiorMaterial?.[0],
  });
};
