import {DataStatus} from 'platform/components';
import {
  Box,
  getSize,
  getValueByDevice,
  Hide,
  HStack,
  Show,
  Space,
  useDevice,
  VStack,
} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {isNotNil} from 'ramda-adjunct';

import {featureFlags, i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {PageBackgroundGradient} from '../../components/PageBackgroundGradient/PageBackgroundGradient';
import {StickyContainer} from '../../components/StickyContainer/StickyContainer';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {RealSellingPriceCard} from './(sections)/RealSellingPriceCard/RealSellingPriceCard';
import {StatisticsCardAge} from './(sections)/StatisticsCardAge/StatisticsCardAge';
import {StatisticsCardColor} from './(sections)/StatisticsCardColor/StatisticsCardColor';
import {StatisticsCardFeatureLevel} from './(sections)/StatisticsCardFeaturesLevel/StatisticsCardFeatureLevel';
import {StatisticsCardMileage} from './(sections)/StatisticsCardMileage/StatisticsCardMileage';
import {StatisticsCardPrice} from './(sections)/StatisticsCardPrice/StatisticsCardPrice';
import {StatisticsCardVAT} from './(sections)/StatisticsCardVAT/StatisticsCardVAT';
import {StatisticsCardVehicle} from './(sections)/StatisticsCardVehicle/StatisticsCardVehicle';
import {UserComplaintCard} from './(sections)/UserComplaintCard/UserComplaintCard';
import {VehicleParametersCard} from './(sections)/VehicleParametersCard/VehicleParametersCard';
import {VehicleHeader} from './components/VehicleHeader';
import {useIsPrintForced} from './hooks/useIsPrintForced';

const PRINT_CONTENT_WIDTH = 240;

export function OverviewPage(props: TestIdProps) {
  const {data, isLoading, isError} = usePriceReport();
  const catalogue = useCatalogue();
  const device = useDevice();
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const currentMarket = data?.markets?.find((m) => m.name === data?.market);
  const maxContentWidth = getResponsiveValue(
    '100%',
    `calc(100% - ${getSize(86)})` as const,
    `calc(100% - ${getSize(114)})` as const,
    `calc(100% - ${getSize(114)})` as const,
    PRINT_CONTENT_WIDTH
  );

  return (
    <>
      <Helmet title={i18n.t('page.overview.title')} />
      <DataStatus isLoading={isLoading} isError={isError} minHeight={20}>
        <PageBackgroundGradient>
          {isNotNil(data) && (
            <VStack shrink={1} maxWidth={315} width="100%">
              <VehicleHeader
                priceReport={data}
                catalogue={catalogue}
                data-testid={suffixTestId('vehicleHeader', props)}
              />
              <Space vertical={6} />
              <HStack
                spacing={6}
                justify="center"
                wrap={getResponsiveValue(true, false, false, false, false)}
              >
                <Hide onMobile when={isPrint}>
                  <Box width={['100%', 80, 108]} flexShrink={0}>
                    <StickyContainer offset={4} isDisabled={getValueByDevice(device, true, false)}>
                      <VStack spacing={6}>
                        <VehicleParametersCard
                          priceReport={data}
                          catalogue={catalogue}
                          data-testid={suffixTestId('vehicleParametersCard', props)}
                        />
                        <UserComplaintCard
                          created={data.created}
                          data-testid={suffixTestId('userComplaintCard', props)}
                        />
                      </VStack>
                    </StickyContainer>
                  </Box>
                </Hide>
                <VStack spacing={6} grow={1} maxWidth={maxContentWidth}>
                  <RealSellingPriceCard
                    priceReport={data}
                    market={currentMarket}
                    data-testid={suffixTestId('realSellingPriceCard', props)}
                  />
                  <Show when={device === 'mobile' || isPrint}>
                    <VehicleParametersCard
                      priceReport={data}
                      catalogue={catalogue}
                      data-testid={suffixTestId('vehicleParametersCard', props)}
                    />
                  </Show>
                  <Show when={isPrint}>
                    <UserComplaintCard
                      created={data.created}
                      data-testid={suffixTestId('userComplaintCard', props)}
                    />
                  </Show>
                  <StatisticsCardPrice
                    market={currentMarket}
                    priceReport={data}
                    data-testid={suffixTestId('statisticsCardPrice', props)}
                  />
                  <StatisticsCardVehicle
                    market={currentMarket}
                    turnover={data.turnover}
                    sellingPrice={data.sellingPrice}
                    data-testid={suffixTestId('statisticsCardVehicle', props)}
                  />
                  <StatisticsCardMileage
                    market={currentMarket}
                    vehicle={data.vehicle}
                    country={data.market}
                    data-testid={suffixTestId('statisticsCardMileage', props)}
                  />
                  <StatisticsCardFeatureLevel
                    market={currentMarket}
                    priceReport={data}
                    data-testid={suffixTestId('statisticsCardFeatureLevel', props)}
                  />
                  <StatisticsCardAge
                    market={currentMarket}
                    priceReport={data}
                    data-testid={suffixTestId('statisticsCardAge', props)}
                  />
                  <Show whenFeatureEnabled={featureFlags.PRICE_REPORT_VAT_CHART}>
                    <StatisticsCardVAT
                      market={currentMarket}
                      vehicle={data.vehicle}
                      data-testid={suffixTestId('statisticsCardVAT', props)}
                    />
                  </Show>
                  <StatisticsCardColor
                    market={currentMarket}
                    vehicle={data.vehicle}
                    data-testid={suffixTestId('statisticsCardColor', props)}
                  />
                  <Show onMobile>
                    <UserComplaintCard
                      created={data.created}
                      data-testid={suffixTestId('userComplaintCard', props)}
                    />
                  </Show>
                </VStack>
              </HStack>
            </VStack>
          )}
        </PageBackgroundGradient>
      </DataStatus>
    </>
  );
}
