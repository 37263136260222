import {differenceInDays, startOfToday} from 'date-fns';

import {isNotEmpty} from 'ramda';

import {StatisticsCar} from '@price-report/shared';

import {parseDate} from 'shared';

export function isSoldIn30Days(car: StatisticsCar) {
  return (
    car.is_sold &&
    isNotEmpty(car.last_occurrence) &&
    differenceInDays(startOfToday(), parseDate(car.last_occurrence)) <= 30
  );
}
