import {IconButton} from 'platform/components';
import {Hide, HStack, Show, Spinner} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

interface ActionsProps extends TestIdProps {
  isDownloadLinkLoading: boolean;
  onShare: () => void;
  onDownload: () => void;
}

export function Actions(props: ActionsProps) {
  return (
    <HStack spacing={2}>
      <Hide when={props.isDownloadLinkLoading}>
        <IconButton
          priority="primary"
          icon="file/download"
          onClick={props.onDownload}
          data-testid={suffixTestId('download', props)}
        />
      </Hide>
      <Show when={props.isDownloadLinkLoading}>
        <Spinner />
      </Show>
      <IconButton
        priority="primary"
        icon="social/share"
        onClick={props.onShare}
        data-testid={suffixTestId('share', props)}
      />
    </HStack>
  );
}
