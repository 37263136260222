import {insert} from 'ramda';
import {floor} from 'ramda-adjunct';

import {PriceReportType} from '@price-report/shared';

import {ExtendedStatisticsCar} from '../components/Chart/types';

export function addMyVehicleToAllCars(
  cars: ExtendedStatisticsCar[],
  priceReport: PriceReportType,
  queryPricePosition: number
): ExtendedStatisticsCar[] {
  return insert<ExtendedStatisticsCar>(
    queryPricePosition,
    {
      ad_id: 'my-vehicle',
      days_on_stock: floor(priceReport?.turnover ?? 0),
      feature_score: priceReport?.featuresLevel ?? 0,
      mileage: priceReport?.vehicle?.mileage ?? 0,
      price_with_vat: priceReport?.sellingPrice?.recommended?.value ?? 0,
      original_price_with_vat: priceReport?.sellingPrice?.recommended?.value ?? 0,
      last_occurrence: '',
      is_available: true,
      is_sold: false,
      isMyVehicle: true,
      position: queryPricePosition + 1,
    },
    cars
  );
}
