import {i18n} from '@price-report/shared';

export const getDiffLabel = (diff: number, formatFn: (diff: number) => string | null) => {
  if (diff > 0) {
    const value = formatFn(diff) || 0;
    return i18n.t('page.overview.yourVehicleMore', {value});
  }
  if (diff < 0) {
    const value = formatFn(-diff) || 0;
    return i18n.t('page.overview.yourVehicleLess', {value});
  }
  return '';
};
