import {ChipsOption, FormControl, FormField, Label, Separator} from 'platform/components';
import {Box, Grid, GridItem, Hide, Link, Show, Text, useDevice, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNotNil} from 'ramda';

import {
  FilterFormData,
  getCurrencyCodeFromString,
  getMileageOptions,
  getPowerOptions,
  getPriceOptions,
  getYearOptions,
  i18n,
  PriceReportType,
} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilter} from '../../hooks/FilterContext';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {AdServerLogo} from '../AdServerLogo/AdServerLogo';
import {getOptionsWithPriority} from './utils/getOptionsWithPriority';

const TRANSMISSION_OPTIONS_SHOWN = 2;
const FUEL_OPTIONS_SHOWN = 2;

export interface FilterProps extends TestIdProps {
  priceReport: PriceReportType;
  formControl: FormControl<FilterFormData>;
  formApi: UseFormReturn<FilterFormData>;
  onReset?: () => void;
}

const yesNoOptions: ChipsOption[] = [
  {label: i18n.t('general.labels.yes'), value: 'YES'},
  {label: i18n.t('general.labels.no'), value: 'NO'},
];

export function Filter(props: FilterProps) {
  const {driveOptions, fuelOptions, styleOptions, transmissionOptions} = useCatalogue();
  const {filter, errors} = useFilter();
  const {data} = usePriceReport();
  const device = useDevice();
  const driveOptionsWithPriority = getOptionsWithPriority(driveOptions, filter?.drive);
  const chipsSize = device === 'mobile' || device === 'tablet' ? 'default' : 'small';
  const isFromCebia = data?.serviceCode === 'cebia' || data?.serviceCode === 'cebia-dev';

  const priceGte = props.formApi.watch('priceGte');
  const priceLte = props.formApi.watch('priceLte');
  const firstRegistrationOnYearGte = props.formApi.watch('firstRegistrationOnYearGte');
  const firstRegistrationOnYearLte = props.formApi.watch('firstRegistrationOnYearLte');
  const mileageGte = props.formApi.watch('mileageGte');
  const mileageLte = props.formApi.watch('mileageLte');
  const powerGte = props.formApi.watch('powerGte');
  const powerLte = props.formApi.watch('powerLte');

  const vehicleTransmissionOptionVisible =
    (transmissionOptions?.findIndex((option) => option.value === data?.vehicle.transmission) ??
      Number.MAX_SAFE_INTEGER) < TRANSMISSION_OPTIONS_SHOWN;
  const vehicleFuelOptionVisible =
    (fuelOptions?.findIndex((option) => option.value === data?.vehicle.fuelType) ??
      Number.MAX_SAFE_INTEGER) < FUEL_OPTIONS_SHOWN;

  return (
    <VStack spacing={4}>
      <Hide when={isFromCebia}>
        <VStack>
          <Label tooltip={i18n.t('entity.filterForm.advertisementServerTooltip')}>
            {i18n.t('entity.filterForm.advertisementServer')}
          </Label>
          <Box paddingVertical={3} paddingHorizontal={2}>
            <AdServerLogo market={props.priceReport.market} maxHeight={5} />
          </Box>
        </VStack>
      </Hide>
      <FormField
        control={props.formControl}
        name="showSoldCars"
        type="switch"
        label={i18n.t('entity.filterForm.labels.showSoldCars')}
        data-testid={suffixTestId('showSoldCars', props)}
      />
      <FormField
        control={props.formControl}
        name="showStockCars"
        type="switch"
        label={i18n.t('entity.filterForm.labels.showStockCars')}
        data-testid={suffixTestId('showStockCars', props)}
      />

      <Grid columns={2} spacing={2} align="flex-end">
        <FormField
          control={props.formControl}
          name="priceGte"
          type="numberInputWithOptions"
          label={`${i18n.t('entity.filterForm.price')} (${
            props.priceReport.purchasePrice?.currency ?? ''
          })`}
          placeholder={i18n.t('entity.filterForm.from')}
          options={getPriceOptions(
            getCurrencyCodeFromString(props.priceReport.purchasePrice.currency),
            null,
            priceLte
          )}
          data-testid={suffixTestId('priceGte', props)}
          isInvalid={!!errors?.price}
          isClearable
        />

        <FormField
          control={props.formControl}
          name="priceLte"
          type="numberInputWithOptions"
          placeholder={i18n.t('entity.filterForm.to')}
          options={getPriceOptions(
            getCurrencyCodeFromString(props.priceReport.purchasePrice.currency),
            priceGte,
            null
          )}
          data-testid={suffixTestId('priceLte', props)}
          isInvalid={!!errors?.price}
          isClearable
        />

        <Show when={!!errors?.price}>
          <GridItem span={2}>
            <Text size="small" color="danger" data-testid={suffixTestId('priceError', props)}>
              {errors?.price}
            </Text>
          </GridItem>
        </Show>
      </Grid>

      <FormField
        control={props.formControl}
        name="isVatDeductible"
        label={i18n.t('entity.filterForm.VATDeductible')}
        type="chips"
        size={chipsSize}
        options={yesNoOptions}
        isMultiple
        data-testid={suffixTestId('isVatDeductible', props)}
      />

      <Grid columns={2} spacing={2} align="flex-end">
        <FormField
          control={props.formControl}
          name="firstRegistrationOnYearGte"
          type="choice"
          label={i18n.t('entity.filterForm.registration')}
          placeholder={i18n.t('entity.filterForm.from')}
          options={getYearOptions(null, firstRegistrationOnYearLte)}
          data-testid={suffixTestId('yearGte', props)}
        />

        <FormField
          control={props.formControl}
          name="firstRegistrationOnYearLte"
          type="choice"
          placeholder={i18n.t('entity.filterForm.to')}
          options={getYearOptions(firstRegistrationOnYearGte)}
          data-testid={suffixTestId('yearLte', props)}
        />
      </Grid>

      <Grid columns={2} spacing={2} align="flex-end">
        <FormField
          control={props.formControl}
          name="mileageGte"
          type="numberInputWithOptions"
          label={`${i18n.t('entity.filterForm.millage')} (km)`}
          placeholder={i18n.t('entity.filterForm.from')}
          options={getMileageOptions(null, mileageLte)}
          data-testid={suffixTestId('mileageGte', props)}
          isInvalid={!!errors?.mileage}
          isClearable
        />

        <FormField
          control={props.formControl}
          name="mileageLte"
          type="numberInputWithOptions"
          placeholder={i18n.t('entity.filterForm.to')}
          options={getMileageOptions(mileageGte)}
          data-testid={suffixTestId('mileageLte', props)}
          isInvalid={!!errors?.mileage}
          isClearable
        />

        <Show when={!!errors?.mileage}>
          <GridItem span={2}>
            <Text size="small" color="danger" data-testid={suffixTestId('mileageError', props)}>
              {errors?.mileage}
            </Text>
          </GridItem>
        </Show>
      </Grid>

      <FormField
        control={props.formControl}
        name="transmission"
        label={i18n.t('entity.filterForm.transmission')}
        type="chips"
        size={chipsSize}
        limit={vehicleTransmissionOptionVisible ? TRANSMISSION_OPTIONS_SHOWN : undefined}
        options={transmissionOptions as ChipsOption[]}
        isMultiple
        data-testid={suffixTestId('transmission', props)}
      />

      <FormField
        label={i18n.t('entity.filterForm.fuel')}
        control={props.formControl}
        name="fuelType"
        type="chips"
        size={chipsSize}
        limit={vehicleFuelOptionVisible ? FUEL_OPTIONS_SHOWN : undefined}
        options={fuelOptions as ChipsOption[]}
        isMultiple
        data-testid={suffixTestId('fuelType', props)}
      />

      <Grid columns={2} spacing={2} align="flex-end">
        <FormField
          control={props.formControl}
          name="powerGte"
          type="numberInputWithOptions"
          label={`${i18n.t('entity.filterForm.power')} (kW)`}
          placeholder={i18n.t('entity.filterForm.from')}
          options={getPowerOptions(null, powerLte)}
          data-testid={suffixTestId('powerGte', props)}
          isInvalid={!!errors?.power}
          isClearable
        />

        <FormField
          control={props.formControl}
          name="powerLte"
          type="numberInputWithOptions"
          placeholder={i18n.t('entity.filterForm.to')}
          options={getPowerOptions(powerGte)}
          data-testid={suffixTestId('powerLte', props)}
          isInvalid={!!errors?.power}
          isClearable
        />

        <Show when={!!errors?.power}>
          <GridItem span={2}>
            <Text size="small" color="danger">
              {errors?.power}
            </Text>
          </GridItem>
        </Show>
      </Grid>

      <FormField
        control={props.formControl}
        name="bodyStyle"
        type="multiChoice"
        label={i18n.t('entity.filterForm.body')}
        options={styleOptions ?? []}
        data-testid={suffixTestId('bodyStyle', props)}
      />

      <FormField
        control={props.formControl}
        name="drive"
        type="chips"
        size={chipsSize}
        label={i18n.t('entity.filterForm.propulsion')}
        limit={driveOptionsWithPriority.length > 2 ? 2 : undefined}
        options={driveOptions ?? []}
        isMultiple
        data-testid={suffixTestId('drive', props)}
      />

      <Show when={isNotNil(props.onReset)}>
        <Separator spacing={1} />
        <Link
          size="small"
          leftIcon="navigation/refresh"
          onClick={props.onReset}
          title={i18n.t('entity.filterForm.restoreOriginalSettings')}
          data-testid={suffixTestId('reset', props)}
        />
      </Show>
    </VStack>
  );
}
