import {DataStatus, Percentage} from 'platform/components';
import {Heading, Text, VStack} from 'platform/foundation';

import {Trans} from 'react-i18next';

import {isNil} from 'ramda';

import {i18n} from '@price-report/shared';

const PERCENTAGE_MULTIPLIER = 100;

interface FeatureLevelProps {
  featureLevel?: number;
  isLoading: boolean;
  isError: boolean;
}

export function FeatureLevelSidebar({featureLevel, isLoading, isError}: FeatureLevelProps) {
  const percentage = isNil(featureLevel) ? 0 : Math.floor(featureLevel * PERCENTAGE_MULTIPLIER);

  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('page.create.labels.featureLevel')}</Heading>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Percentage percentage={percentage} />
      </DataStatus>
      <Text size="small" color="secondary">
        <Trans
          i18nKey="entity.overviewStatistics.labels.featuresDesciption"
          components={{bold: <Text alternative inline size="small" />}}
        />
      </Text>
    </VStack>
  );
}
