import {Hide, VStack} from 'platform/foundation';
import {useFormatNumber, useFormatPercentage} from 'platform/locale';

import {isNil} from 'ramda';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {RealSellingPriceBanner} from './RealSellingPriceBanner';

interface RealSellingPriceCardProps extends TestIdProps {
  priceReport: PriceReportType;
  market?: MarketType;
}

export function RealSellingPriceBanners(props: RealSellingPriceCardProps) {
  const formatNumber = useFormatNumber();
  const formatPercentage = useFormatPercentage();

  const isSomeVehicleSold = (props.market?.sold?.similarVehicle ?? 0) > 0;

  const averageMileage = props.market?.sold?.averageMileage?.value ?? 0;
  const mileageDifference = props.priceReport.vehicle.mileage - averageMileage;

  const mileageBannerVariant = mileageDifference > 0 ? 'warning' : 'success';
  const mileageBannerHeading =
    mileageDifference > 0
      ? i18n.t('entity.priceMeter.labels.mileageBannerHigher', {
          mileage: formatNumber(Math.abs(mileageDifference)),
        })
      : i18n.t('entity.priceMeter.labels.mileageBannerLower', {
          mileage: formatNumber(Math.abs(mileageDifference)),
        });

  const featuresLevel = props.priceReport.featuresLevel ?? 0;
  const averageFeatureLevel = props.market?.sold?.averageFeatureLevel ?? 0;

  const featureDifference = featuresLevel - averageFeatureLevel;

  const featureBannerVariant = featureDifference < 0 ? 'warning' : 'success';
  const featureBannerHeading =
    featureDifference < 0
      ? i18n.t('entity.priceMeter.labels.featureBannerWorse', {
          percentage: formatPercentage(Math.abs(featureDifference), 'nearest'),
        })
      : i18n.t('entity.priceMeter.labels.featureBannerBetter', {
          percentage: formatPercentage(Math.abs(featureDifference), 'nearest'),
        });
  const featureBannerMessage = i18n.t('entity.priceMeter.labels.featureBannerMessage');

  return (
    <VStack spacing={2}>
      <Hide
        when={
          Math.abs(mileageDifference) === 0 ||
          isNil(props.market?.sold?.averageMileage?.value) ||
          !isSomeVehicleSold
        }
      >
        <RealSellingPriceBanner
          variant={mileageBannerVariant}
          heading={mileageBannerHeading}
          data-testid={suffixTestId('mileageBanner', props)}
        />
      </Hide>
      <Hide
        when={
          isNil(props.priceReport.featuresLevel) ||
          isNil(props.market?.sold?.averageFeatureLevel) ||
          !isSomeVehicleSold
        }
      >
        <RealSellingPriceBanner
          variant={featureBannerVariant}
          heading={featureBannerHeading}
          message={featureBannerMessage}
          data-testid={suffixTestId('featureBanner', props)}
        />
      </Hide>
    </VStack>
  );
}
