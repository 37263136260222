import {Box, HStack, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {MouseEvent, ReactNode} from 'react';
import {NavLink, To} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

interface NavigationLinkProps extends TestIdProps {
  to: To;
  isActive?: boolean;
  isDisabled?: boolean;
  shouldScrollIntoView?: boolean;
  children: ReactNode;
}

export function NavigationLink(props: NavigationLinkProps) {
  const scrollIntoView = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!props.shouldScrollIntoView) {
      return;
    }

    (event.target as Element).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const linkContent = (
    <VStack height="100%">
      <HStack height="100%" align="center">
        <Text alternative noWrap>
          <LinkText $isActive={props.isActive} $isDisabled={props.isDisabled}>
            {props.children}
          </LinkText>
        </Text>
      </HStack>
      <Box
        minHeight={1}
        backgroundColor={props.isActive ? 'general.white' : 'general.transparent'}
      />
    </VStack>
  );

  return props.isDisabled ? (
    linkContent
  ) : (
    <NavLink
      to={props.to}
      data-testid={suffixTestId('navigationLink', props)}
      onClick={scrollIntoView}
    >
      {linkContent}
    </NavLink>
  );
}

const LinkText = styled.span<{$isActive?: boolean; $isDisabled?: boolean}>`
  color: ${({theme, $isActive}) =>
    $isActive ? theme.colors.text.white : theme.colors.palettes.neutral[60][100]};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.4 : 1)};
  cursor: ${({$isDisabled}) => ($isDisabled ? 'default' : 'pointer')};
`;
