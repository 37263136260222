export function SilverGradientDefinition() {
  return (
    <defs>
      <linearGradient id="silverGradient" x1="-9.66%" y1="31.98%" x2="109.66%" y2="68.02%">
        <stop offset="13.2%" stopColor="#E9E9E9" />
        <stop offset="54.97%" stopColor="#FFFFFF" />
        <stop offset="98.52%" stopColor="#EAEAEA" />
      </linearGradient>
    </defs>
  );
}
