import {
  Grid,
  Heading,
  Hide,
  HStack,
  Icon,
  Show,
  Text,
  ThemeIconKey,
  VStack,
} from 'platform/foundation';

import {ReactNode} from 'react';

import {i18n, mileageFormatter, powerFormatter, VehicleType} from '@price-report/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {CatalogueType} from '../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../hooks/useVehicleTranslatedInfo';
import {VehicleLogo} from '../VehicleLogo/VehicleLogo';

interface VehicleInfoProps extends TestIdProps {
  catalogue: CatalogueType;
  vehicle: VehicleType;
  children?: ReactNode;
}

export function VehicleInfo(props: VehicleInfoProps) {
  const {data: translatedVehicleInfo} = useVehicleTranslatedInfo(props.vehicle, true);
  const {getCarStyle, getFuelType, getDrive, getTransmission} = props.catalogue;

  const vehicleTitle = String(`${translatedVehicleInfo.make}   ${translatedVehicleInfo.model}`);
  const formattedFeatures = buildArray<{icon: ThemeIconKey; text: string | number}>()
    .add({icon: 'automotive/calendar', text: props.vehicle.firstRegistrationOnYear})
    .add({icon: 'automotive/engine', text: powerFormatter(props.vehicle.power)})
    .add({icon: 'automotive/differential', text: getDrive(props.vehicle.drive)})
    .add({icon: 'automotive/mileage', text: mileageFormatter(props.vehicle.mileage)})
    .add({icon: 'automotive/transmission', text: getTransmission(props.vehicle.transmission)})
    .add({icon: 'automotive/fuel_type', text: getFuelType(props.vehicle.fuelType)})
    .add({icon: 'automotive/car', text: getCarStyle(props.vehicle.bodyStyle)})
    .when(props.vehicle.isVatDeductible, {
      icon: 'automotive/paid',
      text: i18n.t('entity.vehicleInfo.labels.vatDeductible'),
    });

  const features = formattedFeatures.map((feature) => (
    <HStack key={feature.icon} spacing={2}>
      <Icon value={feature.icon} size={4} />
      <Text
        inline
        size="xSmall"
        color="secondary"
        alternative
        data-testid={suffixTestId('features', props)}
      >
        {feature.text}
      </Text>
    </HStack>
  ));

  return (
    <HStack align="center">
      <Hide onMobile onTablet>
        <VehicleLogo vehicle={props.vehicle} width={19} height={12} />
      </Hide>
      <VStack grow={1} spacing={[4, 4, 2, 2]}>
        <HStack justify="space-between" align="center" wrap spacing={2}>
          <HStack spacing={2} align="center">
            <Show onMobile onTablet>
              <VehicleLogo vehicle={props.vehicle} width={7} height={7} />
            </Show>
            <Heading size={3} data-testid={suffixTestId('makeModel', props)}>
              {vehicleTitle}
            </Heading>
          </HStack>
          {props.children}
        </HStack>
        <Hide onMobile onTablet>
          <HStack spacing={4} wrap>
            {features}
          </HStack>
        </Hide>
        <Show onMobile onTablet>
          <Grid columns={[2, 4, 0, 0]} spacing={3}>
            {features}
          </Grid>
        </Show>
      </VStack>
    </HStack>
  );
}
