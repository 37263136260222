import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import {catalogueApi} from './catalogueApi';
import {omneticApi} from './omneticApi';
import {omneticToolsApi} from './omneticToolsApi';
import {priceReportApi} from './priceReportApi';

export const store = configureStore({
  // Add the generated reducer as a specific top-level slice
  reducer: {
    [priceReportApi.reducerPath]: priceReportApi.reducer,
    [catalogueApi.reducerPath]: catalogueApi.reducer,
    [omneticApi.reducerPath]: omneticApi.reducer,
    [omneticToolsApi.reducerPath]: omneticToolsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      priceReportApi.middleware,
      catalogueApi.middleware,
      omneticApi.middleware,
      omneticToolsApi.middleware
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
