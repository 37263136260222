import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {i18n} from '../i18n/i18n';

export const numberFormatter = (value: number | Nullish) => {
  if (isNil(value)) {
    return '-';
  }
  return new Intl.NumberFormat(i18n.language).format(value);
};
