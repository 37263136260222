import {DataStatus} from 'platform/components';
import {Box, Hide, Show, ThemeColorPath} from 'platform/foundation';

import {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useMatch} from 'react-router-dom';

import {SelectedFiltersProvider} from '../../hooks/SelectedFiltersContext';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {useVehicleTranslatedInfo} from '../../hooks/useVehicleTranslatedInfo';
import {ErrorPage} from '../../pages/ErrorPage/ErrorPage';
import {ExpiredPage} from '../../pages/ExpiredPage/ExpiredPage';
import {useIsPrintForced} from '../../pages/Overview/hooks/useIsPrintForced';
import {getTestId} from '../../utils/getTestId';
import {createPath, createRoute} from '../../utils/routing';
import {Footer} from './components/Footer/Footer';
import {Header} from './components/Header/Header';

const PRINT_WIDTH = 297;

export function Layout() {
  const {
    data: priceReport,
    isLoading,
    isError,
    isNotCreated,
    isNotFound,
    isExpired,
  } = usePriceReport();
  const {isLoading: isCatalogueLoading} = useCatalogue();
  const {isLoading: isLoadingVehicleTranslatedInfo} = useVehicleTranslatedInfo(
    priceReport?.vehicle
  );

  const isCreatePage = !!useMatch(createPath);
  const shouldRedirect = !isCreatePage && isNotCreated;
  const {isPrintForced, getResponsiveValue} = useIsPrintForced();

  const pageBackgroundColor: ThemeColorPath = getResponsiveValue(
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'general.white'
  );

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box width={isPrintForced ? PRINT_WIDTH : 'auto'}>
      {shouldRedirect && <Navigate to={createRoute} />}
      <Hide when={isNotFound || isExpired}>
        <SelectedFiltersProvider>
          <DataStatus
            minHeight="100vh"
            isError={isError}
            isLoading={
              isLoading || isCatalogueLoading || isLoadingVehicleTranslatedInfo || shouldRedirect
            }
            data-testid={getTestId('layout', 'dataStatus')}
          >
            <Box backgroundColor={pageBackgroundColor}>
              <Header data-testid={getTestId('layout', 'header')} />
              <Outlet />
              <Footer data-testid={getTestId('layout', 'footer')} />
            </Box>
          </DataStatus>
        </SelectedFiltersProvider>
      </Hide>
      <Show when={isExpired}>
        <ExpiredPage data-testid="expiredPage" />
      </Show>
      <Show when={isNotFound}>
        <ErrorPage data-testid="errorPage" />
      </Show>
    </Box>
  );
}
