import {init, browserTracingIntegration} from '@sentry/browser';

const SENTRY_DSN = 'https://66ae7ded23bb424fb6737978ffc21f71@o354794.ingest.sentry.io/6163043';

export function initSentry() {
  init({
    dsn: SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    denyUrls: ['localhost'],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    normalizeMaxBreadth: 10,
  });
}
