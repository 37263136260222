import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';

import {RequestVehicle} from '@price-report/shared';

import {Nullish} from 'shared';

import {WITHOUT_VAT} from '../constants/withoutVAT';
import {WITH_VAT} from '../constants/withVAT';
import {VehicleFormType} from '../types/VehicleFormType';

const convertDoorCount = (doorCount: string): string =>
  match(doorCount)
    .with('DOORCOUNT_2', () => 'DOORCOUNT_2_3')
    .with('DOORCOUNT_3', () => 'DOORCOUNT_2_3')
    .with('DOORCOUNT_4', () => 'DOORCOUNT_4_5')
    .with('DOORCOUNT_5', () => 'DOORCOUNT_4_5')
    .with('DOORCOUNT_6', () => 'DOORCOUNT_6_7')
    .with('DOORCOUNT_7', () => 'DOORCOUNT_6_7')
    .otherwise(() => doorCount);

export const getDefaultValues = (vehicle: RequestVehicle | Nullish): Partial<VehicleFormType> => ({
  bodyStyle: vehicle?.bodyStyle ? [vehicle.bodyStyle] : undefined,
  drive: vehicle?.drive ? [vehicle.drive] : undefined,
  features: vehicle?.features ?? undefined,
  firstRegistrationOnYear: vehicle?.firstRegistrationOnYear ?? undefined,
  fuelType: vehicle?.fuelType ? [vehicle.fuelType] : undefined,
  isVatDeductible: vehicle?.isVatDeductible ? [WITH_VAT] : [WITHOUT_VAT],
  make: vehicle?.make ?? undefined,
  modelFamily: vehicle?.modelFamily ?? undefined,
  mileage: vehicle?.mileage ?? undefined,
  power: vehicle?.power ?? undefined,
  transmission: vehicle?.transmission ? [vehicle.transmission] : undefined,
  vin: vehicle?.vin ?? undefined,
  exteriorColor: vehicle?.exteriorColor ? [vehicle.exteriorColor] : undefined,
  doorCount: vehicle?.doorCountCategory ? [convertDoorCount(vehicle.doorCountCategory)] : undefined,
  seatCount: isNotNil(vehicle?.seatCount) ? [`${vehicle?.seatCount ?? ''}`] : undefined,
  interiorMaterial: vehicle?.interiorMaterial ? [vehicle.interiorMaterial] : undefined,
});
