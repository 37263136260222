import {Device} from 'platform/foundation';
import {formatCurrencySymbol, currencies} from 'platform/locale';
import {match} from 'ts-pattern';

import {i18n} from '@price-report/shared';

import {CurrencyCodeType} from 'shared';

import {ChartVariant} from './chartVariant';

export function getYAxisLabel(
  chartVariant: ChartVariant,
  currencyCode: CurrencyCodeType,
  device: Device
): string | undefined {
  if (device === 'mobile') {
    return undefined;
  }

  return match(chartVariant)
    .with('price', () =>
      i18n.t('entity.statisticsChart.labels.priceInThousands', {
        currencySymbol: formatCurrencySymbol(currencies, currencyCode),
      })
    )
    .with('mileage', () => i18n.t('entity.statisticsChart.labels.mileageInThousands'))
    .with('daysOnStock', () => i18n.t('entity.chartOptions.daysOnStock'))
    .with('featuresLevel', () => i18n.t('entity.statisticsChart.labels.featuresLevel'))
    .with('priceMap', () =>
      i18n.t('entity.statisticsChart.labels.priceInThousands', {
        currencySymbol: formatCurrencySymbol(currencies, currencyCode),
      })
    )
    .exhaustive();
}
