import {Flag, Rank, Tooltip} from 'platform/components';
import {Heading, Hide, HStack, Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {CompareTrend} from '../../../components/CompareTrend/CompareTrend';

interface HorizontalValueProps extends TestIdProps {
  value: number | null | undefined;
  isMyVehicle: boolean;
  formatter?: (value: number | null | undefined) => string;
  withRank?: boolean;
  difference?: number;
  differenceReverseColor?: boolean;
  differenceFormatter?: (value: number | null | undefined) => string;
  isPrice?: true;
  hasVatFlag?: boolean;
}

export function HorizontalValue(props: HorizontalValueProps) {
  const percentage = isNotNil(props.value) ? Math.floor(props.value * 100) : null;

  return (
    <HStack spacing={1} align="center" justify="flex-end" wrap>
      <Hide when={props.isMyVehicle}>
        <CompareTrend
          value={props.difference ?? 0}
          formatter={props.differenceFormatter ?? props.formatter}
          reverseColor={props.differenceReverseColor}
        />
      </Hide>
      <Show when={props.hasVatFlag}>
        <Tooltip label={i18n.t('general.labels.vatDeductible')}>
          <Flag
            colorScheme="black"
            size="small"
            label={i18n.t('general.labels.vat')}
            data-testid={suffixTestId('vatDeductible', props)}
          />
        </Tooltip>
      </Show>
      <HStack spacing={2} align="center">
        <Heading
          size={props.isPrice ? 4 : 5}
          alternative={props.isPrice ? undefined : true}
          data-testid={suffixTestId('value', props)}
        >
          {props.formatter?.(props.value) ?? `${props.value ?? '-'}`}
        </Heading>
        <Show when={props.withRank}>
          <Rank percentage={percentage} data-testid={suffixTestId('rank', props)} />
        </Show>
      </HStack>
    </HStack>
  );
}
