import {Card, Trend} from 'platform/components';
import {
  Align,
  Box,
  Heading,
  Hide,
  HStack,
  Icon,
  Space,
  Text,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Trans} from 'react-i18next';

import {isNil, isNotNil, sort} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {ResponsiveGrid} from '../../components/ResponsiveGrid';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {getDiffLabel} from '../../utils/getDiffLabel';
import {getValueOrEmptyString} from '../../utils/getValueOrEmptyString';
import {ActualPriceChart} from './components/ActualPriceChart';
import {AveragePriceChart} from './components/AveragePriceChart';
import {getStatisticVariant} from './utils/getStatisticVariant';

interface StatisticsCardPriceProps extends TestIdProps {
  market?: MarketType;
  priceReport: PriceReportType;
}

export function StatisticsCardPrice(props: StatisticsCardPriceProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const formatCurrency = useFormatCurrency();
  const variant = getStatisticVariant(
    props.priceReport.sellingPrice?.recommended?.value ?? 0,
    props.market?.sold?.averagePrice?.value ?? 0
  );

  const alertTranslationKey = match(variant)
    .with('success', () => 'entity.overviewStatistics.labels.priceAlertSuccess')
    .with('neutral', () => 'entity.overviewStatistics.labels.priceAlertNeutral')
    .with('warning', () => 'entity.overviewStatistics.labels.priceAlertWarning')
    .exhaustive();

  const messageColor: ThemeColorPath =
    variant === 'warning' ? 'palettes.yellow.20.100' : 'palettes.green.20.100';

  const currency = props.priceReport?.sellingPrice?.recommended?.currency;
  const recommended = props.priceReport?.sellingPrice?.recommended?.value;
  const sold = props.market?.sold?.averagePrice?.value;
  const stock = props.market?.stock?.averagePrice?.value;

  const isSomeVehicleSold = (props.market?.sold?.similarVehicle ?? 0) > 0;
  const isSomeVehicleInStock = (props.market?.stock?.similarVehicle ?? 0) > 0;

  const averagePriceSold = isSomeVehicleSold ? props.market?.sold?.averagePrice : undefined;
  const averagePriceStock = isSomeVehicleInStock ? props.market?.stock?.averagePrice : undefined;

  const averageSoldDifferent =
    isNil(recommended) || isNil(sold) || !isSomeVehicleSold ? undefined : recommended - sold;
  const averageStockDifferent =
    isNil(recommended) || isNil(stock) || !isSomeVehicleInStock ? undefined : recommended - stock;

  const priceChartData = props.market?.priceChart?.original_price_with_vat;
  const chartData = isNotNilOrEmpty(priceChartData)
    ? sort(
        (a, b) => a - b,
        buildArray<number>(priceChartData).when(
          isNotNil(props.priceReport.sellingPrice?.recommended?.value),
          props.priceReport?.sellingPrice?.recommended?.value ?? 0
        )
      )
    : undefined;

  return (
    <AvoidBreakInside>
      <Card variant={isPrint ? 'inlineWhite' : undefined}>
        <Box padding={2}>
          <VStack spacing={6}>
            <Align center>
              <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.priceTitle')}</Heading>
            </Align>
            <Text align="center" size="small" color="secondary">
              <Trans
                i18nKey="entity.overviewStatistics.labels.priceDesciption"
                components={{bold: <Text alternative inline size="small" />}}
              />
            </Text>
            <ResponsiveGrid>
              <Box>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.recommendedVehiclePrice')}
                  </Text>
                  <Space vertical={2} />
                  <Heading size={2} data-testid={suffixTestId('recommendedSellingPrice', props)}>
                    {getValueOrEmptyString(
                      props.priceReport.sellingPrice?.recommended,
                      (recommended) => formatCurrency(recommended.value, recommended.currency)
                    )}
                  </Heading>
                </Align>
              </Box>
              <Box>
                <VStack spacing={2} align="center">
                  <Align center>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.overviewStatistics.labels.averageVehiclePrice')}
                    </Text>
                    <Space vertical={2} />
                    <Heading size={2} data-testid={suffixTestId('soldAveragePrice', props)}>
                      {getValueOrEmptyString(averagePriceSold, (averagePrice) =>
                        formatCurrency(averagePrice.value, averagePrice.unit)
                      )}
                    </Heading>
                  </Align>
                  {isNotNil(averageSoldDifferent) && isNotNil(currency) && (
                    <Trend
                      label={getDiffLabel(averageSoldDifferent, (value) =>
                        formatCurrency(value, currency)
                      )}
                      isSubtle
                      isSmall
                      isPositive={averageSoldDifferent > 0}
                      isNegative={averageSoldDifferent < 0}
                      data-testid={suffixTestId('averageSoldDifferenceTrend', props)}
                    />
                  )}
                </VStack>
              </Box>
              <Box>
                <VStack align="center" spacing={2}>
                  <Align center>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.overviewStatistics.labels.stockVehiclePrice')}
                    </Text>
                    <Space vertical={2} />
                    <Heading size={2} data-testid={suffixTestId('stockAveragePrice', props)}>
                      {getValueOrEmptyString(averagePriceStock, (averagePrice) =>
                        formatCurrency(averagePrice.value, averagePrice.unit)
                      )}
                    </Heading>
                  </Align>
                  {isNotNil(averageStockDifferent) && isNotNil(currency) && (
                    <Trend
                      label={getDiffLabel(averageStockDifferent, (value) =>
                        formatCurrency(value, currency)
                      )}
                      isSubtle
                      isSmall
                      isPositive={averageStockDifferent > 0}
                      isNegative={averageStockDifferent < 0}
                      data-testid={suffixTestId('averageStockDifferenceTrend', props)}
                    />
                  )}
                </VStack>
              </Box>
            </ResponsiveGrid>
            <Hide
              when={
                isNil(props.priceReport.sellingPrice?.recommended?.value) ||
                isNil(props.market?.sold?.averagePrice?.value) ||
                !isSomeVehicleSold
              }
            >
              <Box backgroundColor={messageColor} borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Hide when={variant === 'neutral'}>
                    <Icon
                      value={variant === 'success' ? 'action/trending_up' : 'action/trending_down'}
                      color={variant === 'success' ? 'severity.success' : 'severity.warning'}
                      size={7}
                    />
                  </Hide>
                  <Text
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                    size="small"
                  >
                    <Trans
                      i18nKey={alertTranslationKey}
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </Hide>
            <ActualPriceChart
              chartData={chartData}
              averagePrice={props.market?.sold?.averagePrice}
              vehiclePrice={props.priceReport.sellingPrice?.recommended?.value}
            />
            <AveragePriceChart
              averageSoldPrice={props.market?.averagePriceChart?.sold}
              averageStockPrice={props.market?.averagePriceChart?.stock}
              period={props.market?.averagePriceChart?.period}
            />
            <Box backgroundColor="palettes.neutral.20.100" borderRadius="small" padding={4}>
              <HStack spacing={6} align="center">
                <Icon value="action/info_outline" />
                <Text
                  align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  size="small"
                >
                  <Trans
                    i18nKey="entity.overviewStatistics.labels.lowerVehicleSellingPrice"
                    components={{bold: <Text alternative inline size="small" />}}
                  />
                </Text>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </Card>
    </AvoidBreakInside>
  );
}
