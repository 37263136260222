import {Card, Trend} from 'platform/components';
import {
  Align,
  Box,
  Heading,
  Hide,
  HStack,
  Icon,
  Space,
  Text,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {Trans} from 'react-i18next';

import {isNil, isNotNil} from 'ramda';

import {i18n, MarketType, VehicleType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {ResponsiveGrid} from '../../components/ResponsiveGrid';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {getDiffLabel} from '../../utils/getDiffLabel';
import {getValueOrEmptyString} from '../../utils/getValueOrEmptyString';
import {MileageChart} from './components/MileageChart';
import {getStatisticVariant} from './utils/getStatisticVariant';

interface StatisticsCardMileageProps extends TestIdProps {
  market?: MarketType;
  vehicle: VehicleType;
  country: string;
}

export function StatisticsCardMileage(props: StatisticsCardMileageProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const formatNumber = useFormatNumber();
  const variant = getStatisticVariant(
    props.vehicle.mileage,
    props.market?.sold?.averageMileage?.value ?? 0
  );

  const alertTranslationKey = match(variant)
    .with('success', () => 'entity.overviewStatistics.labels.mileageAlertSuccess')
    .with('neutral', () => 'entity.overviewStatistics.labels.mileageAlertNeutral')
    .with('warning', () => 'entity.overviewStatistics.labels.mileageAlertWarning')
    .exhaustive();

  const messageColor: ThemeColorPath =
    variant === 'warning' ? 'palettes.yellow.20.100' : 'palettes.green.20.100';

  const sold = props.market?.sold?.averageMileage?.value;
  const stock = props.market?.stock?.averageMileage?.value;

  const isSomeVehicleSold = (props.market?.sold?.similarVehicle ?? 0) > 0;
  const isSomeVehicleInStock = (props.market?.stock?.similarVehicle ?? 0) > 0;

  const averageMileageSold = isSomeVehicleSold ? props.market?.sold?.averageMileage : undefined;
  const averageMileageStock = isSomeVehicleInStock
    ? props.market?.stock?.averageMileage
    : undefined;

  const soldDifference =
    isNil(sold) || !isSomeVehicleSold ? undefined : props.vehicle.mileage - sold;
  const stockDifference =
    isNil(stock) || !isSomeVehicleInStock ? undefined : props.vehicle.mileage - stock;

  return (
    <AvoidBreakInside>
      <Card variant={isPrint ? 'inlineWhite' : undefined}>
        <Box padding={2}>
          <VStack spacing={6}>
            <Align center>
              <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.mileageTitle')}</Heading>
            </Align>
            <Text align="center" size="small" color="secondary">
              <Trans
                i18nKey="entity.overviewStatistics.labels.mileageDescription"
                components={{bold: <Text alternative inline size="small" />}}
              />
            </Text>
            <ResponsiveGrid>
              <Box>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.mileageYourCar')}
                  </Text>
                  <Space vertical={2} />
                  <Heading size={2} data-testid={suffixTestId('vehicleMileage', props)}>
                    {`${formatNumber(props.vehicle.mileage, 0)} ${i18n.t('general.metric.km')}`}
                  </Heading>
                </Align>
              </Box>
              <Box>
                <VStack spacing={2} align="center">
                  <Align center>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.overviewStatistics.labels.mileageSoldCars')}
                    </Text>
                    <Space vertical={2} />
                    <Heading size={2} data-testid={suffixTestId('soldAverageMileage', props)}>
                      {getValueOrEmptyString(
                        averageMileageSold,
                        (averageMileage) =>
                          `${formatNumber(averageMileage.value, 0)} ${averageMileage.unit}`
                      )}
                    </Heading>
                  </Align>
                  {isNotNil(soldDifference) && (
                    <Trend
                      label={getDiffLabel(
                        soldDifference,
                        (value) => `${formatNumber(value)} ${i18n.t('general.metric.km')}`
                      )}
                      isSubtle
                      isSmall
                      isPositive={soldDifference < 0}
                      isNegative={soldDifference > 0}
                      isReverseIcon
                      data-testid={suffixTestId('soldDifferenceTrend', props)}
                    />
                  )}
                </VStack>
              </Box>
              <Box>
                <VStack align="center" spacing={2}>
                  <Align center>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.overviewStatistics.labels.mileageStockCars')}
                    </Text>
                    <Space vertical={2} />
                    <Heading size={2} data-testid={suffixTestId('stockAverageMileage', props)}>
                      {getValueOrEmptyString(
                        averageMileageStock,
                        (averageMileage) =>
                          `${formatNumber(averageMileage.value, 0)} ${averageMileage.unit}`
                      )}
                    </Heading>
                  </Align>
                  {isNotNil(stockDifference) && (
                    <Trend
                      label={getDiffLabel(
                        stockDifference,
                        (value) => `${formatNumber(value)} ${i18n.t('general.metric.km')}`
                      )}
                      isSubtle
                      isSmall
                      isPositive={stockDifference < 0}
                      isNegative={stockDifference > 0}
                      isReverseIcon
                      data-testid={suffixTestId('stockDifferenceTrend', props)}
                    />
                  )}
                </VStack>
              </Box>
            </ResponsiveGrid>
            <Hide when={isNil(props.market?.sold?.averageMileage?.value) || !isSomeVehicleSold}>
              <Box backgroundColor={messageColor} borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Hide when={variant === 'neutral'}>
                    <Icon
                      value={variant === 'success' ? 'action/trending_up' : 'action/trending_down'}
                      color={variant === 'success' ? 'severity.success' : 'severity.warning'}
                      size={7}
                    />
                  </Hide>
                  <Text
                    size="small"
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  >
                    <Trans
                      i18nKey={alertTranslationKey}
                      values={{
                        mileage: getValueOrEmptyString(
                          soldDifference,
                          (difference) =>
                            `${formatNumber(Math.abs(difference), 0)} ${i18n.t(
                              'general.metric.km'
                            )}`
                        ),
                      }}
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </Hide>
            <MileageChart vehicle={props.vehicle} mileageChartData={props.market?.mileageChart} />
          </VStack>
        </Box>
      </Card>
    </AvoidBreakInside>
  );
}
