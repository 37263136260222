import {Heading} from 'platform/foundation';

import {Nullish} from 'shared';

interface FooterHeadingProps {
  children: Nullish | string;
}

export function FooterHeading(props: FooterHeadingProps) {
  return (
    <Heading color="secondary" size={4}>
      {props.children}
    </Heading>
  );
}
