import {Card} from 'platform/components';
import {
  Align,
  Box,
  Heading,
  Hide,
  HStack,
  Icon,
  Text,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';

import {Trans} from 'react-i18next';

import {isNilOrEmpty} from 'ramda-adjunct';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {YearChart} from './components/YearChart';

interface StatisticsCardAgeProps extends TestIdProps {
  market?: MarketType;
  priceReport: PriceReportType;
}

export function StatisticsCardAge(props: StatisticsCardAgeProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const formatPercentage = useFormatPercentage();

  const soldVehiclesInYears =
    props.market?.registrationYearChart?.statisticsAggregated?.map(({statistics, levels}) => ({
      amount: statistics.find((statistic) => statistic.type === 'sold')?.amount ?? 0,
      year: props.market?.registrationYearChart?.translations?.[
        levels.find((level) => level.name === 'year')?.value ?? ''
      ],
    })) ?? [];
  const maxSoldVehiclesByYear = Math.max(...soldVehiclesInYears.map(({amount}) => amount));
  const maxSoldVehiclesYears = soldVehiclesInYears
    .filter(({amount}) => amount === maxSoldVehiclesByYear)
    .map(({year}) => year)
    .join(', ');

  const soldVehiclesInMyYear = soldVehiclesInYears.find(
    ({year}) => year === String(props.priceReport.vehicle.firstRegistrationOnYear)
  )?.amount;

  const isMyVehicleSuccesYear = soldVehiclesInMyYear === maxSoldVehiclesByYear;

  const messageColor: ThemeColorPath = isMyVehicleSuccesYear
    ? 'palettes.green.20.100'
    : 'palettes.yellow.20.100';

  if (
    isNilOrEmpty(props.market?.registrationYearChart?.statisticsAggregated) &&
    soldVehiclesInYears.length === 0
  ) {
    return null;
  }

  return (
    <AvoidBreakInside>
      <Card variant={isPrint ? 'inlineWhite' : undefined}>
        <Box padding={2}>
          <VStack spacing={6}>
            <Align center>
              <Heading size={3} data-testid={suffixTestId('sectionHeading', props)}>
                {i18n.t('entity.overviewStatistics.labels.ageTitle')}
              </Heading>
            </Align>
            <Text align="center" size="small" color="secondary">
              <Trans
                i18nKey="entity.overviewStatistics.labels.ageDesciption"
                components={{bold: <Text alternative inline size="small" />}}
              />
            </Text>
            <YearChart
              registrationYearChart={props.market?.registrationYearChart}
              vehicleYear={props.priceReport.vehicle.firstRegistrationOnYear}
            />
            <Hide when={soldVehiclesInYears.length === 0}>
              <Box backgroundColor={messageColor} borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Icon
                    value={isMyVehicleSuccesYear ? 'action/trending_up' : 'action/trending_down'}
                    color={isMyVehicleSuccesYear ? 'severity.success' : 'severity.warning'}
                    size={7}
                  />
                  <Text
                    size="small"
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  >
                    <Trans
                      i18nKey={
                        isMyVehicleSuccesYear
                          ? 'entity.overviewStatistics.labels.ageAlertSuccess'
                          : 'entity.overviewStatistics.labels.ageAlertWarning'
                      }
                      values={{
                        mostSuccessYear: maxSoldVehiclesYears,
                        percentage: formatPercentage(
                          (soldVehiclesInMyYear ?? 0) / maxSoldVehiclesByYear,
                          'nearest'
                        ),
                      }}
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </Hide>
          </VStack>
        </Box>
      </Card>
    </AvoidBreakInside>
  );
}
