import {Box, HStack, Image} from 'platform/foundation';

import {NavLink} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

export function SocialNetworks(props: TestIdProps) {
  return (
    <HStack spacing={6}>
      <SocialNetwork name="facebook" to="#" data-testid={suffixTestId('socialNetwork', props)} />
      <SocialNetwork name="youtube" to="#" data-testid={suffixTestId('socialNetwork', props)} />
      <SocialNetwork name="linkedin" to="#" data-testid={suffixTestId('socialNetwork', props)} />
    </HStack>
  );
}

interface SocialNetworkProps extends TestIdProps {
  name: string;
  to: string;
}

function SocialNetwork(props: SocialNetworkProps) {
  return (
    <NavLink to={props.to}>
      <Box backgroundColor="general.accent" padding={1} borderRadius="small">
        <Image
          src={`assets/images/footer-social-networks/${props.name}.svg`}
          data-testid={suffixTestId(props.name, props)}
        />
      </Box>
    </NavLink>
  );
}
