import {Card, useDialog} from 'platform/components';
import {Box, Clickable, Hide, HStack, Icon, Image, Text, VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {UserComplaintDialog} from './components/UserComplaintDialog';

interface UserComplaintCardProps extends TestIdProps {
  created: string;
}

export function UserComplaintCard(props: UserComplaintCardProps) {
  const {isPrint} = useIsPrintForced();
  const [isOpen, onOpen, onClose] = useDialog();
  const generationDate = parseDate(props.created).toLocaleDateString(i18n?.resolvedLanguage);

  return (
    <Card variant={isPrint ? 'inlineWhite' : undefined}>
      <HStack spacing={7} justify="space-between">
        <VStack spacing={4}>
          <Text size="xxSmall" color="secondary">
            {i18n.t('page.overview.actions.pricingDescription', {generationDate})}
          </Text>
          <Hide when={isPrint}>
            <Clickable onClick={onOpen}>
              <HStack spacing={1} align="center">
                <Icon size={4} color="text.danger" value="alert/warning" />
                <Text size="small" color="danger">
                  {i18n.t('page.overview.actions.reportError')}
                </Text>
              </HStack>
            </Clickable>
          </Hide>
        </VStack>
        <Box flexShrink={0}>
          <Image
            src="/assets/images/powered_by_carsdata.svg"
            alt="Powered by carsdata"
            width="4.6875rem"
            height="2.6875rem"
            data-testid={suffixTestId('carsdataLogo', props)}
          />
        </Box>
      </HStack>
      <UserComplaintDialog
        isOpen={isOpen}
        onClose={onClose}
        data-testid={suffixTestId('userComplaintDialog', props)}
      />
    </Card>
  );
}
