import {Card} from 'platform/components';
import {Align, Box, Heading, Hide, HStack, Icon, Text, VStack} from 'platform/foundation';

import {Trans} from 'react-i18next';

import {isNil} from 'ramda';

import {i18n, MarketType, VehicleType} from '@price-report/shared';

import {TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {VATChart} from './components/VATChart';

interface StatisticsCardVATProps extends TestIdProps {
  vehicle: VehicleType;
  market?: MarketType;
}

export function StatisticsCardVAT(props: StatisticsCardVATProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const isDeductible = props.vehicle.isVatDeductible;

  return (
    <Hide when={isNil(props.market?.vatChart)}>
      <AvoidBreakInside>
        <Card variant={isPrint ? 'inlineWhite' : undefined}>
          <Box padding={2}>
            <VStack spacing={6}>
              <Align center>
                <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.vatTitle')}</Heading>
              </Align>
              <Text align="center" size="small" color="secondary">
                <Trans
                  i18nKey="entity.overviewStatistics.labels.vatDescription"
                  components={{bold: <Text alternative inline size="small" />}}
                />
              </Text>

              <VATChart vatChartData={props.market?.vatChart} />

              <Box
                backgroundColor={isDeductible ? 'palettes.green.20.100' : 'palettes.yellow.20.100'}
                borderRadius="small"
                padding={4}
              >
                <HStack spacing={6} align="center">
                  <Icon
                    value={isDeductible ? 'action/trending_up' : 'action/trending_down'}
                    color={isDeductible ? 'severity.success' : 'severity.warning'}
                    size={7}
                  />
                  <Text
                    size="small"
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  >
                    <Trans
                      i18nKey={
                        isDeductible
                          ? 'entity.overviewStatistics.labels.vatDeductibleAlert'
                          : 'entity.overviewStatistics.labels.vatNonDeductibleAlert'
                      }
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </VStack>
          </Box>
        </Card>
      </AvoidBreakInside>
    </Hide>
  );
}
