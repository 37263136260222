import {Box, HStack, Image} from 'platform/foundation';

import {Link, useMatch, useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

import {createPath, getCreatePath, getOverviewPath} from '../../../../../utils/routing';

export function Logo(props: TestIdProps) {
  const params = useParams();
  const isCreatePage = Boolean(useMatch(createPath));

  return (
    <Box height={12}>
      <HStack height="100%" align="center">
        <Link
          to={isCreatePage ? getCreatePath(params) : getOverviewPath(params)}
          data-testid={suffixTestId('linkToRoot', props)}
        >
          <Image
            src="assets/images/logo.svg"
            maxWidth={125}
            data-testid={suffixTestId('logo', props)}
          />
        </Link>
      </HStack>
    </Box>
  );
}
