import {Rectangle, RectangleProps} from 'recharts';

export function ColorBar(props: RectangleProps) {
  const isWhite = props.fill === '#FFFFFF';
  const isGradient = props.fill?.substring(0, 15) === 'linear-gradient';
  const fill = isGradient ? 'url(#silverGradient)' : props.fill;
  const strokeProps = isWhite ? {stroke: '#D8D8D8', strokeWidth: 1} : {};

  return <Rectangle {...props} {...strokeProps} fill={fill} />;
}
