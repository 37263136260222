import {useCallback, useEffect, useRef} from 'react';
import {FieldValues, UseFormReturn} from 'react-hook-form';
import {BlockerFunction, useBlocker, useInRouterContext, Blocker} from 'react-router-dom';

import {equals, isNotEmpty, not} from 'ramda';

import {OptionsType, composePath, isFeatureEnabled, useNavigate} from 'shared';

interface UseUnsavedChangesNotificationProps<TFieldValues extends FieldValues> {
  formApi: UseFormReturn<TFieldValues, unknown, undefined>;
  isDisabled: boolean;
}

const UNBLOCKED_STATE: Blocker = {
  state: 'unblocked',
  reset: undefined,
  proceed: undefined,
  location: undefined,
};

const UNSAVED_FORMS_FF_V2 = 'core_unsaved_forms_v2';

export function useUnsavedChangesNotification<TFieldValues extends FieldValues>(
  props: UseUnsavedChangesNotificationProps<TFieldValues>
) {
  const navigate = useNavigate();

  // Used for edge cases, where we want to leave form with unsaved changes
  const shouldNavigateWithoutBlocker = useRef<boolean>(false);

  const isWithinRouterContext = useInRouterContext();

  const isDirty = isNotEmpty(props.formApi.formState.dirtyFields);
  const isSubmitting = props.formApi.formState.isSubmitting;

  const shouldBlock = useCallback<BlockerFunction>(
    ({currentLocation, nextLocation}) =>
      isDirty &&
      isFeatureEnabled(UNSAVED_FORMS_FF_V2) &&
      not(props.isDisabled) &&
      not(shouldNavigateWithoutBlocker.current) &&
      not(isSubmitting) &&
      not(equals(currentLocation, nextLocation)),
    [isDirty, isSubmitting, props.isDisabled]
  );

  const getUnblockedState = () => UNBLOCKED_STATE;

  const useAppropriateBlocker = isWithinRouterContext ? useBlocker : getUnblockedState;

  const blocker = useAppropriateBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && not(isDirty)) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  const navigateWithoutBlocker = <Path extends string>(path: Path, options?: OptionsType<Path>) => {
    shouldNavigateWithoutBlocker.current = true;
    navigate(composePath(path, options));
    shouldNavigateWithoutBlocker.current = false;
  };

  return {blocker, navigateWithoutBlocker};
}
