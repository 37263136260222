import {createContext, ReactNode, useContext, useState} from 'react';

import {FilterFormData} from '@price-report/shared';

import {noop} from 'shared';

type SelectedFiltersContextType = {
  selectedFilters: FilterFormData | null;
  onSelectFilters: (data: FilterFormData) => void;
};

const SelectedFiltersContext = createContext<SelectedFiltersContextType>({
  selectedFilters: null,
  onSelectFilters: noop,
});

type SelectedFiltersProviderProps = {
  children: ReactNode;
};

const SelectedFiltersProvider = (props: SelectedFiltersProviderProps) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterFormData | null>(null);

  const context: SelectedFiltersContextType = {
    selectedFilters,
    onSelectFilters: setSelectedFilters,
  };

  return (
    <SelectedFiltersContext.Provider value={context}>
      {props.children}
    </SelectedFiltersContext.Provider>
  );
};

const useSelectedFilters = () => useContext(SelectedFiltersContext);

export {SelectedFiltersProvider, useSelectedFilters};
