import {DataStatus} from 'platform/components';
import {Box} from 'platform/foundation';

import {useEffect, useRef} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {PriceReportType} from '@price-report/shared';

import {Nullish} from 'shared';

import {CatalogueType} from '../../../../../hooks/useCatalogue';
import {useGetPriceReportCarDetailQuery} from '../../../../../store/priceReportApi';
import {VehicleCard} from '../../../../AdvertisementPage/components/VehicleCard';

interface SelectedVehicleCardProps {
  priceReport: PriceReportType;
  vehicleId: string | Nullish;
  catalogue: CatalogueType;
}

export function SelectedVehicleCard(props: SelectedVehicleCardProps) {
  const vehicleCardRef = useRef<HTMLDivElement>(null);
  const {data, isFetching, isError} = useGetPriceReportCarDetailQuery(
    {reportId: props.priceReport.id, vehicleId: props.vehicleId ?? ''},
    {skip: isNilOrEmpty(props.vehicleId)}
  );

  useEffect(() => {
    if (data) {
      vehicleCardRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  }, [data]);

  if (isNilOrEmpty(props.vehicleId)) {
    return null;
  }

  return (
    <DataStatus isLoading={isFetching} isError={isError} minHeight={46}>
      {data && (
        <Box minHeight={46} ref={vehicleCardRef}>
          <VehicleCard
            catalogue={props.catalogue}
            priceReport={props.priceReport}
            vehicle={{...data, isMyVehicle: false}}
            isMyVehicle={false}
          />
        </Box>
      )}
    </DataStatus>
  );
}
