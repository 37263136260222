import {getSize} from 'platform/foundation';
import styled from 'styled-components';

export const PageBackgroundGradient = styled.div`
  background: linear-gradient(0deg, #3e47dd 0%, #262a98 100%) no-repeat top;
  background-size: 100% ${getSize(106)};
  min-height: ${getSize(106)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getSize(8)};
  @media only screen and ${({theme}) => theme.devices.mobile} {
    padding: ${getSize(4)};
  }
  @media only screen and ${({theme}) => theme.devices.tablet} {
    padding: ${getSize(4)};
  }
`;
