import {
  Box,
  Heading,
  HStack,
  Icon,
  Integer,
  Link,
  LinkProps,
  Show,
  Text,
  ThemeColorPath,
  ThemeIconKey,
} from 'platform/foundation';
import {match} from 'ts-pattern';

import {ReactElement} from 'react';

import {isNotNil, map} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../IconButton/IconButton';

export type Hyperlink = {title: string} & Omit<LinkProps, 'children'>;

export type AlertVariants = 'info' | 'success' | 'warning' | 'error';
export type AlertTypes = 'banner' | 'inline';

export interface AlertProps extends TestIdProps {
  type?: AlertTypes;
  variant?: AlertVariants;
  title?: string;
  message?: Nullish | string | string[];
  hyperlinks?: Hyperlink[];
  onClose?: () => void;
}

export function Alert(props: AlertProps) {
  const {title, message, hyperlinks, onClose, variant, type} = props;

  const icon = match<AlertVariants, ThemeIconKey>(variant ?? 'warning')
    .with('info', () => 'action/info')
    .with('warning', () => 'alert/warning')
    .with('success', () => 'action/check_circle')
    .with('error', () => 'alert/error')
    .exhaustive();

  const backgroundColor = match<AlertVariants, ThemeColorPath>(variant ?? 'warning')
    .with('info', () => 'palettes.blue.10.100')
    .with('warning', () => 'palettes.orange.10.100')
    .with('success', () => 'palettes.green.10.100')
    .with('error', () => 'palettes.red.10.100')
    .exhaustive();

  const borderColor = match<AlertVariants, ThemeColorPath>(variant ?? 'warning')
    .with('info', () => 'palettes.blue.30.100')
    .with('warning', () => 'palettes.orange.30.100')
    .with('success', () => 'palettes.green.30.100')
    .with('error', () => 'palettes.red.30.100')
    .exhaustive();

  const severityColor = match<AlertVariants, ThemeColorPath>(variant ?? 'warning')
    .with('info', () => 'severity.informational')
    .with('warning', () => 'severity.warning')
    .with('success', () => 'severity.success')
    .with('error', () => 'severity.danger')
    .exhaustive();

  const border = match<AlertTypes, string>(type ?? 'banner')
    .with('banner', () => '1px solid')
    .with('inline', () => 'none')
    .exhaustive();

  const borderLeft = match<AlertTypes, string>(type ?? 'banner')
    .with('banner', () => '3px solid')
    .with('inline', () => 'none')
    .exhaustive();

  const padding = match<AlertTypes, Integer>(type ?? 'banner')
    .with('banner', () => 3)
    .with('inline', () => 2)
    .exhaustive();

  return (
    <Box
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderTop={border}
      borderRight={border}
      borderBottom={border}
      role="alert"
      data-testid={suffixTestId('alertWrapper', props)}
    >
      <Box padding={padding} borderColor={severityColor} borderLeft={borderLeft}>
        <HStack spacing={3} justify="space-between" align="center">
          <HStack spacing={3} align="center">
            <Show when={isNotNil(icon)}>
              <Icon
                value={icon}
                size={5}
                color={severityColor}
                data-testid={suffixTestId('alertIcon', props)}
              />
            </Show>
            <HStack spacing={1} align="center">
              <Show when={isNotNil(title)}>
                <Heading size={5} data-testid={suffixTestId('alertTitle', props)}>
                  {title}
                </Heading>
              </Show>
              <Show when={isNotNil(message)}>
                <Text size="small" data-testid={suffixTestId('alertDescription', props)}>
                  {Array.isArray(message) ? getMultilineMessage(message) : message}
                </Text>
              </Show>
            </HStack>
          </HStack>
          <HStack spacing={3} align="center">
            <Show when={isNotNil(hyperlinks)}>
              {hyperlinks?.map((hyperlink) => (
                <Link
                  key={hyperlink.title}
                  data-testid={suffixTestId('alertHyperlink', props)}
                  {...hyperlink}
                />
              ))}
            </Show>
            <Show when={isNotNil(onClose)}>
              <IconButton
                data-testid={suffixTestId('alertCloseButton', props)}
                size="small"
                onClick={onClose}
                icon="navigation/close"
              />
            </Show>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
}

const getMultilineMessage = map<string, ReactElement>((line) => <div key={line}>{line}</div>);
