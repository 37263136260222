import {Box, HStack, Image} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {useIsPrintForced} from '../../../../../pages/Overview/hooks/useIsPrintForced';

export function PaymentLogos(props: TestIdProps) {
  const {getResponsiveValue} = useIsPrintForced();

  return (
    <HStack spacing={getResponsiveValue(3, 4, 4, 4, 4)}>
      <PaymentLogo
        logoName="barion"
        logoOriginalWidth={66}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="visa"
        logoOriginalWidth={45}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="visa-electron"
        logoOriginalWidth={45}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="mastercard"
        logoOriginalWidth={36}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="maestro"
        logoOriginalWidth={36}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="diners-club"
        logoOriginalWidth={42}
        data-testid={suffixTestId('paymentLogo', props)}
      />
      <PaymentLogo
        logoName="discover"
        logoOriginalWidth={44}
        data-testid={suffixTestId('paymentLogo', props)}
      />
    </HStack>
  );
}

interface PaymentLogoProps extends TestIdProps {
  logoName: string;
  logoOriginalWidth: number;
}

function PaymentLogo(props: PaymentLogoProps) {
  return (
    <Box flexGrow={props.logoOriginalWidth}>
      <Image
        src={`assets/images/footer-payment/${props.logoName}.svg`}
        data-testid={suffixTestId(props.logoName, props)}
      />
    </Box>
  );
}
