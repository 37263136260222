import {AvailableCar, PriceReportType} from '@price-report/shared';

import {mapMetadaCurrency} from './mapMetadaCurrency';

/**
 * Get price of the vehicle
 *   - price_with_vat is original_price_with_vat converted to priceReportCurrency
 *   - when original_currency is the same as priceReportCurrency, price_with_vat
 *     double converted and thus inaccurate
 * @param vehicle
 * @param priceReport
 */
export const getPrice = (vehicle?: AvailableCar, priceReport?: PriceReportType) => {
  const originalCurrency = mapMetadaCurrency(vehicle?.original_currency);
  const priceReportCurrency = priceReport?.sellingPrice.recommended.currency;
  return (
    (originalCurrency === priceReportCurrency
      ? vehicle?.original_price_with_vat
      : vehicle?.price_with_vat) ?? 0
  );
};
