import {getSize} from 'platform/foundation';
import styled from 'styled-components';

export const VehicleBodyColorDot = styled.div<{$backgroundColor: string}>`
  background: ${(props) => props.$backgroundColor};
  width: ${getSize(4)};
  height: ${getSize(4)};
  border-radius: ${getSize(2)};
  border: 1px solid ${(props) => props.theme.colors.general.separator};
`;
