import {i18n} from '@price-report/shared';

export type ChartVariant = 'price' | 'mileage' | 'daysOnStock' | 'featuresLevel' | 'priceMap';
export const chartVariantOptions: Array<{value: ChartVariant; label: string}> = [
  {value: 'priceMap', label: i18n.t('entity.chartOptions.priceMap')},
  {value: 'price', label: i18n.t('entity.chartOptions.price')},
  {value: 'mileage', label: i18n.t('entity.chartOptions.mileage')},
  {value: 'daysOnStock', label: i18n.t('entity.chartOptions.daysOnStock')},
  {value: 'featuresLevel', label: i18n.t('entity.chartOptions.featuresLevel')},
];
