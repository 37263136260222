import {match} from 'ts-pattern';

import {i18n, SellerType} from '@price-report/shared';

import {Nullish} from 'shared';

export const translateSellerType = (value: SellerType | Nullish) =>
  value
    ? match(value)
        .with('SELLERTYPE_PRIVATE_SELLER', () => i18n.t('general.labels.sellerTypePrivate'))
        .with('SELLERTYPE_DEALERSHIP', () => i18n.t('general.labels.sellerTypeDealership'))
        .with('SELLERTYPE_PARTNER_DEALERSHIP', () =>
          i18n.t('general.labels.sellerTypePartnerDealership')
        )
        .exhaustive()
    : null;
