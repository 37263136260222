/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
export enum featureFlags {
  PRICE_REPORT_DRILL_DOWN = 'pricereport_drilldown',
  PRICE_REPORT_VAT_CHART = 'pricereport_vat_chart',
}
/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
