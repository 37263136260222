import {Box, Heading, Hide, Icon, Text, useElementSize} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {isNil, isNotNil, max, min} from 'ramda';

import {i18n} from '@price-report/shared';

export const TOOLTIP_HEIGHT = 96;

interface ThisVehicleTooltipProps {
  // injected by recharts, type of this prop does not exist in recharts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formattedGraphicalItems?: any[];
  xPositionOfThisVehicle: number;
  shouldShowCircle?: boolean;
  additionalText?: string;
  yOffset?: number;
}

const TOOLTIP_POINT_R = 6;

export function ThisVehicleTooltip(props: ThisVehicleTooltipProps) {
  const theme = useTheme();
  const [tooltipRef, tooltipWidth] = useElementSize<HTMLDivElement>();
  const [additionalTextRef, ...additionalTextDimensions] = useElementSize<HTMLDivElement>();
  const additionalTextHeight = additionalTextDimensions[1] ?? 0;

  const rechartsProps = props.formattedGraphicalItems?.[0]?.props;
  const rechartsPoints = rechartsProps?.data ?? rechartsProps?.points;
  const columnsPerItem = props.formattedGraphicalItems?.length ?? 0;
  const startX = rechartsPoints?.[0]?.x;
  const endX =
    rechartsPoints?.[rechartsPoints.length - 1]?.x +
    (rechartsPoints?.[rechartsPoints.length - 1]?.width ?? 0) * columnsPerItem;
  const thisVehicleX =
    (rechartsPoints?.[props.xPositionOfThisVehicle]?.x ?? 0) +
    ((rechartsPoints?.[props.xPositionOfThisVehicle]?.width ?? 0) / 2) * columnsPerItem;
  const positionX = thisVehicleX - startX - (tooltipWidth ?? 0) / 2;
  const lastAvailablePositionX = endX - startX - (tooltipWidth ?? 0);
  const tooltipPositionX = max(0, min(lastAvailablePositionX, positionX));
  const thisVehicleColumnsY =
    props.formattedGraphicalItems?.map(
      (item) => (item.props?.data ?? item.props.points)[props.xPositionOfThisVehicle]?.y
    ) ?? [];
  const thisVehiclePositionY =
    (isNotNil(props.formattedGraphicalItems) ? Math.min(...thisVehicleColumnsY) : 0) -
    (props.yOffset ?? 0);
  const pointX = min(thisVehicleX, endX - (props.shouldShowCircle ? TOOLTIP_POINT_R : 0));

  return (
    <>
      {props.shouldShowCircle && (
        <circle
          cx={pointX}
          r={TOOLTIP_POINT_R}
          cy={thisVehiclePositionY}
          fill={theme.colors.palettes.blue[60][100]}
        />
      )}
      <foreignObject
        x={startX}
        y={thisVehiclePositionY - (46 + additionalTextHeight)}
        height={32 + additionalTextHeight}
        width={endX - startX}
        pointerEvents="none"
      >
        {/* Square – useful when tooltip is at the edge of the chart */}
        {/* We need positioning in pixels; no platform components support that */}
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <div style={{position: 'fixed', top: 12 + additionalTextHeight, left: pointX - startX - 8}}>
          <Box width={4} height={3} backgroundColor="palettes.blue.60.100" />
        </div>

        {/* Tooltip */}
        <div
          ref={tooltipRef}
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            position: 'fixed',
            left: tooltipPositionX,
          }}
        >
          <Box
            paddingVertical={1}
            paddingHorizontal={2}
            borderRadius="small"
            backgroundColor="palettes.blue.60.100"
          >
            <Text color="white" size="xSmall" alternative noWrap align="center">
              {i18n.t('entity.overviewStatisticsMileageChart.labels.thisVehicle')}
            </Text>
            <Hide when={isNil(props.additionalText)}>
              <Box ref={additionalTextRef}>
                <Heading size={4} color="white">
                  {props.additionalText}
                </Heading>
              </Box>
            </Hide>
          </Box>
        </div>

        {/* Beak */}
        <div
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            position: 'fixed',
            top: 10 + additionalTextHeight,
            left: pointX - startX - 16,
          }}
        >
          <Icon value="navigation/arrow_drop_down" color="palettes.blue.60.100" size={8} />
        </div>
      </foreignObject>
    </>
  );
}
