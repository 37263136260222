import {Box, Space, useDevice} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';
import {Bar, ComposedChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {useTheme} from 'styled-components';

import {i18n, MarketType} from '@price-report/shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {ChartLegend} from '../../../components/ChartLegend';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';

const roundPercent = (value: number) => Math.round(value * 100) / 100;

interface VATChartProps {
  vatChartData?: MarketType['vatChart'];
}

export function VATChart({vatChartData}: VATChartProps) {
  const theme = useTheme();
  const formatPercentage = useFormatPercentage();
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();

  const sold =
    (vatChartData?.vatReclaimable?.sold ?? 0) + (vatChartData?.vatNonReclaimable?.sold ?? 0);
  const stock =
    (vatChartData?.vatReclaimable?.stock ?? 0) + (vatChartData?.vatNonReclaimable?.stock ?? 0);
  const vatNonReclaimableSoldRatio =
    sold === 0 ? 0 : roundPercent((vatChartData?.vatNonReclaimable?.sold ?? 0) / sold);
  const vatNonReclaimableStockRatio =
    stock === 0 ? 0 : roundPercent((vatChartData?.vatNonReclaimable?.stock ?? 0) / stock);

  const chartData = [
    {
      name: i18n.t('entity.vatChart.labels.vatDeductible'),
      sold: 1 - vatNonReclaimableSoldRatio, // This prevents the situation when sum of values is 101%
      stock: 1 - vatNonReclaimableStockRatio, // This prevents the situation when sum of values is 101%
    },
    {
      name: i18n.t('entity.vatChart.labels.vatNonDeductible'),
      sold: vatNonReclaimableSoldRatio,
      stock: vatNonReclaimableStockRatio,
    },
  ];

  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false);

  return (
    <Box>
      <ChartLegend />
      <Space vertical={4} />
      <ResponsiveContainer width={chartWidth} height={150}>
        <ComposedChart data={chartData} layout="vertical" margin={{right: 40}}>
          <Bar
            dataKey="sold"
            fill={theme.colors.palettes.neutral[60][100]}
            radius={2}
            minPointSize={2}
            label={({x, y, width, height, value}) => (
              <text
                x={x + width}
                y={y + height / 2}
                dx={6}
                dy={4}
                fontSize={12}
                fill={theme.colors.palettes.neutral[200][100]}
              >
                {formatPercentage(value, 'nearest')}
              </text>
            )}
            isAnimationActive={isAnimationActive}
          />
          <Bar
            dataKey="stock"
            fill={theme.colors.palettes.orange[60][100]}
            radius={2}
            minPointSize={2}
            label={({x, y, width, height, value}) => (
              <text
                x={x + width}
                y={y + height / 2}
                dx={6}
                dy={4}
                fontSize={12}
                fill={theme.colors.palettes.orange[60][100]}
              >
                {formatPercentage(value, 'nearest')}
              </text>
            )}
            isAnimationActive={isAnimationActive}
          />
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="name"
            fontSize={12}
            axisLine={false}
            tickLine={false}
            width={150}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}
