import {Box, Hide, Space, useDevice} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import {Bar, ComposedChart, Customized, Label, ResponsiveContainer, XAxis} from 'recharts';
import {useTheme} from 'styled-components';

import {last} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {DrillDownResponse, i18n, VehicleType} from '@price-report/shared';

import {Nullish} from 'shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {convertMileageToAlphaMileageRange} from '../../../../../utils/convertMileageToAlphaMileageRange';
import {ChartLegend} from '../../../components/ChartLegend';
import {ThisVehicleTooltip, TOOLTIP_HEIGHT} from '../../../components/ThisVehicleTooltip';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';

interface MileageChartProps {
  vehicle: VehicleType;
  mileageChartData?: DrillDownResponse | Nullish;
}

export function MileageChart(props: MileageChartProps) {
  const theme = useTheme();
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();
  const formatNumber = useFormatNumber();

  const chartData =
    props.mileageChartData?.statisticsAggregated?.map((item) => ({
      name: last(item.levels)?.value,
      stock: item.statistics.find((i) => i.type === 'stock')?.amount ?? 0,
      sold: item.statistics.find((i) => i.type === 'sold')?.amount ?? 0,
    })) ?? [];

  const xPositionOfThisVehicle = chartData
    .map((item) => item.name)
    .indexOf(convertMileageToAlphaMileageRange(props.vehicle.mileage));

  const isSmallDevice = device === 'mobile' || device === 'tablet';
  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false);

  return (
    <Hide when={isNilOrEmpty(props.mileageChartData?.statisticsAggregated)}>
      <Box>
        <ChartLegend />
        <Space vertical={2} />
        <ResponsiveContainer width={chartWidth} height={250}>
          <ComposedChart
            data={chartData}
            margin={
              isSmallDevice
                ? {bottom: 40, right: 20, top: TOOLTIP_HEIGHT + 16}
                : {top: TOOLTIP_HEIGHT + 16}
            }
            /* CSS is needed to allow line label overflow from the chart, because it is moved up (`dy={-6}`) */
            /* eslint-disable-next-line react/forbid-component-props */
            css={`
              .recharts-surface {
                overflow: visible;
              }
            `}
          >
            <Bar
              dataKey="sold"
              fill={theme.colors.palettes.neutral[60][100]}
              radius={2}
              minPointSize={2}
              label={({x, y, width, value}) => (
                <text
                  x={x + width / 2}
                  y={y}
                  textAnchor="middle"
                  dy={-6}
                  fontSize={12}
                  fill={theme.colors.palettes.neutral[200][100]}
                >
                  {value}
                </text>
              )}
              isAnimationActive={isAnimationActive}
            />
            <Bar
              dataKey="stock"
              fill={theme.colors.severity.warning}
              radius={2}
              minPointSize={2}
              label={({x, y, width, value}) => (
                <text
                  x={x + width / 2}
                  y={y}
                  textAnchor="middle"
                  dy={-6}
                  fontSize={12}
                  fill={theme.colors.palettes.orange[80][100]}
                >
                  {value}
                </text>
              )}
              isAnimationActive={isAnimationActive}
            />
            <XAxis
              dataKey="name"
              fontSize={12}
              fontWeight="bold"
              axisLine={false}
              tickLine={false}
              dy={5}
              height={54}
              minTickGap={0}
              tick={{fill: theme.colors.text.primary}}
              angle={isSmallDevice ? 60 : 0}
              textAnchor={isSmallDevice ? 'start' : 'middle'}
            >
              <Label
                dy={isSmallDevice ? 40 : undefined}
                value={i18n.t('entity.overviewStatisticsMileageChart.labels.xAxisLabel')}
                position="insideBottom"
                fill={theme.colors.text.primary}
                fontSize={12}
              />
            </XAxis>
            {xPositionOfThisVehicle >= 0 && (
              <Customized
                component={
                  <ThisVehicleTooltip
                    xPositionOfThisVehicle={xPositionOfThisVehicle}
                    additionalText={`${formatNumber(props.vehicle.mileage, 0)}\xA0${i18n.t(
                      'general.metric.km'
                    )}`}
                  />
                }
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Hide>
  );
}
