export function getStatisticVariant(
  myCarMileage: number,
  soldCarsMileage: number
): 'success' | 'warning' | 'neutral' {
  if (myCarMileage > soldCarsMileage + 5000) {
    return 'warning';
  }
  if (myCarMileage < soldCarsMileage - 5000) {
    return 'success';
  }
  return 'neutral';
}
