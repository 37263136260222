import {Box, Hide, HStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Pagination, PaginationProps} from './Pagination';

interface DesktopPaginationProps extends PaginationProps, TestIdProps {
  children?: ReactNode;
}

export function DesktopPagination(props: DesktopPaginationProps) {
  return (
    <Hide onMobile onTablet>
      {/* minHeight={8} is here, because we wan't to keep the same height regardless of shown components */}
      <Box minHeight={8}>
        <HStack spacing={6} align="center">
          <Pagination {...props} data-testid={suffixTestId('pagination', props)} />
          {props.children}
        </HStack>
      </Box>
    </Hide>
  );
}
