import {Card} from 'platform/components';
import {
  Align,
  Box,
  Heading,
  Hide,
  HStack,
  Icon,
  Space,
  Text,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Trans} from 'react-i18next';

import {isNil} from 'ramda';
import {floor} from 'ramda-adjunct';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {ResponsiveGrid} from '../../components/ResponsiveGrid';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {getValueOrEmptyString} from '../../utils/getValueOrEmptyString';
import {AverageStockChart} from './components/AverageStockChart';
import {getStatisticVariant} from './utils/getStatisticVariant';
import {getTurnoverRange} from './utils/getTurnoverRange';

interface StatisticsCardVehicleProps extends TestIdProps {
  market?: MarketType;
  turnover: number | Nullish;
  sellingPrice?: PriceReportType['sellingPrice'];
}

export function StatisticsCardVehicle(props: StatisticsCardVehicleProps) {
  const {isPrint, getResponsiveValue} = useIsPrintForced();
  const formatCurrency = useFormatCurrency();

  const variant = getStatisticVariant(
    props.market?.sold?.similarVehicle ?? 0,
    props.market?.stock?.similarVehicle ?? 0
  );

  const isTurnoverNil = isNil(props.turnover);

  const alertTranslationKey = match(variant)
    .with('success', () =>
      isTurnoverNil
        ? 'entity.overviewStatistics.labels.vehiclesAlertSuccessNilTurnover'
        : 'entity.overviewStatistics.labels.vehiclesAlertSuccess'
    )
    .with('neutral', () =>
      isTurnoverNil
        ? 'entity.overviewStatistics.labels.vehiclesAlertNeutralNilTurnover'
        : 'entity.overviewStatistics.labels.vehiclesAlertNeutral'
    )
    .with('warning', () =>
      isTurnoverNil
        ? 'entity.overviewStatistics.labels.vehiclesAlertWarningNilTurnover'
        : 'entity.overviewStatistics.labels.vehiclesAlertWarning'
    )
    .exhaustive();

  const messageColor: ThemeColorPath =
    variant === 'warning' ? 'palettes.yellow.20.100' : 'palettes.green.20.100';

  return (
    <AvoidBreakInside>
      <Card variant={isPrint ? 'inlineWhite' : undefined}>
        <Box padding={2}>
          <VStack spacing={6}>
            <Align center>
              <Heading size={3}>{i18n.t('entity.overviewStatistics.labels.salesTitle')}</Heading>
            </Align>
            <Text align="center" size="small" color="secondary">
              <Trans
                i18nKey="entity.overviewStatistics.labels.salesDesciption"
                components={{bold: <Text alternative inline size="small" />}}
              />
            </Text>
            <ResponsiveGrid>
              <Box flex={1}>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.turnover')}
                  </Text>
                </Align>
                <Space vertical={2} />
                <HStack justify="center">
                  <Heading size={2} data-testid={suffixTestId('turnover', props)}>
                    {isNil(props.turnover)
                      ? i18n.t('entity.overviewStatistics.labels.notAvailable')
                      : i18n.t('general.labels.nDays', {count: floor(props.turnover)})}
                  </Heading>
                </HStack>
              </Box>
              <Box flex={1}>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.soldInThreeMonths')}
                  </Text>
                </Align>
                <Space vertical={2} />
                <Align center>
                  <Heading size={2} data-testid={suffixTestId('soldSimilarVehicle', props)}>
                    {i18n.t('entity.overviewStatistics.labels.vehiclesCount', {
                      count: props.market?.sold?.similarVehicle ?? 0,
                    })}
                  </Heading>
                </Align>
              </Box>
              <Box flex={1}>
                <Align center>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.overviewStatistics.labels.currentlyAdvertised')}
                  </Text>
                </Align>
                <Space vertical={2} />
                <Align center>
                  <Heading size={2} data-testid={suffixTestId('stockSimilarVehicle', props)}>
                    {i18n.t('entity.overviewStatistics.labels.vehiclesCount', {
                      count: props.market?.stock?.similarVehicle ?? 0,
                    })}
                  </Heading>
                </Align>
              </Box>
            </ResponsiveGrid>
            <Hide
              when={
                isNil(props.market?.sold?.similarVehicle) ||
                isNil(props.market?.stock?.similarVehicle)
              }
            >
              <Box backgroundColor={messageColor} borderRadius="small" padding={4}>
                <HStack spacing={6} align="center">
                  <Hide when={variant === 'neutral'}>
                    <Icon
                      value={variant === 'success' ? 'action/trending_up' : 'action/trending_down'}
                      color={variant === 'success' ? 'severity.success' : 'severity.warning'}
                      size={7}
                    />
                  </Hide>
                  <Text
                    size="small"
                    align={getResponsiveValue('left', 'left', 'center', 'center', 'center')}
                  >
                    <Trans
                      i18nKey={alertTranslationKey}
                      values={{
                        days: getValueOrEmptyString(props.turnover, (turnover) =>
                          getTurnoverRange(turnover)
                        ),
                        price: getValueOrEmptyString(
                          props.sellingPrice?.recommended,
                          (recommended) => formatCurrency(recommended.value, recommended.currency)
                        ),
                      }}
                      components={{bold: <Text alternative inline size="small" />}}
                    />
                  </Text>
                </HStack>
              </Box>
            </Hide>
            <AverageStockChart
              averageSold={props.market?.averageStockChart?.sold}
              averageStock={props.market?.averageStockChart?.stock}
              period={props.market?.averageStockChart?.period}
            />
          </VStack>
        </Box>
      </Card>
    </AvoidBreakInside>
  );
}
