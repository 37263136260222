import {Text, ThemeColorTextPath} from 'platform/foundation';
import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

interface ListTextProps extends TestIdProps {
  isRow?: boolean;
  value: string;
  showDot: boolean;
  textColor: ThemeColorTextPath;
  showBackground: boolean;
  isMyVehicle?: boolean;
  isMissing?: boolean;
  isBold?: boolean;
}

export function ListText(props: ListTextProps) {
  return (
    <>
      <TextWrapper
        $isRow={props.isRow}
        $showBackground={props.showBackground}
        $isMyVehicle={props.isMyVehicle}
      >
        <Text
          color={props.isMissing ? 'danger' : props.textColor}
          size="xSmall"
          alternative={!props.isMissing && props.isBold}
          data-testid={suffixTestId('text', props)}
          noWrap
          title={props.value}
        >
          {props.value}
        </Text>
      </TextWrapper>
      <DotWrapper $isRow={props.isRow} $show={props.showDot}>
        <Text
          color={props.textColor}
          size={props.isRow ? 'xSmall' : 'small'}
          data-testid={suffixTestId('dot', props)}
        >
          •
        </Text>
      </DotWrapper>
    </>
  );
}

// TODO: T20-15854 - change to responsive components from platform
// eslint-disable-next-line eag/no-css-property
const TextWrapper = styled.div<{
  $isRow?: boolean;
  $showBackground: boolean;
  $isMyVehicle?: boolean;
}>`
  display: flex;
  align-items: center;
  max-width: 100%;
  min-height: ${({$isRow, theme}) => ($isRow ? 'unset' : theme.getSize(7))};
  padding-top: ${({$isRow, theme}) => ($isRow ? theme.getSize(0) : theme.getSize(1))};
  padding-bottom: ${({$isRow, theme}) => ($isRow ? theme.getSize(0) : theme.getSize(1))};
  padding-left: ${({$isRow, theme}) => ($isRow ? theme.getSize(0) : theme.getSize(2))};
  padding-right: ${({$isRow, theme}) => ($isRow ? theme.getSize(0) : theme.getSize(2))};
  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  background: ${({$isRow, $showBackground, theme, $isMyVehicle}) =>
    !$isRow && $showBackground
      ? $isMyVehicle
        ? theme.colors.palettes.teal[40][100]
        : theme.colors.palettes.neutral[10][100]
      : 'initial'};
  @media only screen and (max-width: ${({theme}) => theme.breakPoints.SMALL_UP}) {
    padding: ${({theme}) => theme.getSize(0)};
    background: initial;
    min-height: unset;
    & > div {
      font-size: ${({theme}) => theme.fontSizes.text.xSmall};
      line-height: ${({theme}) => theme.lineHeights.text.xSmall};
    }
  }
`;

// TODO: T20-15854 - change to responsive components from platform
// eslint-disable-next-line eag/no-css-property
const DotWrapper = styled.div<{$isRow?: boolean; $show: boolean}>`
  display: ${({$show, $isRow}) => ($isRow && $show ? 'flex' : 'none')};
  align-items: center;
  @media only screen and (max-width: ${({theme}) => theme.breakPoints.SMALL_UP}) {
    display: ${({$show}) => ($show ? 'flex' : 'none')};
    & > div {
      font-size: ${({theme}) => theme.fontSizes.text.xSmall};
      line-height: ${({theme}) => theme.lineHeights.text.xSmall};
    }
  }
`;
