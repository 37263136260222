import {isNil} from 'ramda';
import {isNotNaN, isNumber} from 'ramda-adjunct';

export const getDifference = (
  first: number | null | undefined,
  second: number | null | undefined,
  precision?: number
) => {
  if (isNumber(first) && isNotNaN(first) && isNumber(second) && isNotNaN(second)) {
    return isNil(precision)
      ? first - second
      : (Math.floor(first * precision) - Math.floor(second * precision)) / precision;
  }

  return 0;
};
