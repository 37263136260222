import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {environment} from '@price-report/environment';
import {
  GetAllEnumsApiArg,
  GetAllEnumsResponse,
  GetApiMakeModelWithMakeApiArg,
  GetApiMakeModelWithMakeApiResponse,
  GetMakeModelApiArg,
  GetMakeModelResponse,
} from '@price-report/shared';

export const catalogueApi = createApi({
  reducerPath: 'catalogue',
  baseQuery: fetchBaseQuery({baseUrl: environment.CATALOGUE_URL}),
  endpoints: (build) => ({
    getAllEnums: build.query<GetAllEnumsResponse, GetAllEnumsApiArg>({
      query: (queryArg) => ({url: `/api/enum`, params: {lang: queryArg.lang}}),
    }),
    getApiMakeModelWithMake: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelWithMakeApiArg
    >({
      query: (queryArg) => {
        const URL_PREFIX = '/api/make-model';

        const url = match([isNotNilOrEmpty(queryArg.vehicleType), isNotNilOrEmpty(queryArg.make)])
          .with([true, true], always(`${URL_PREFIX}/${queryArg.vehicleType}/${queryArg.make}`))
          .with([true, false], always(`${URL_PREFIX}/${queryArg.vehicleType}`))
          .otherwise(always(`${URL_PREFIX}`));

        return {
          url,
          params: {lang: queryArg.lang},
        };
      },
    }),
    getMakeModel: build.query<GetMakeModelResponse, GetMakeModelApiArg>({
      query: (queryArg) => ({
        url: `/api/make-model/${queryArg.vehicleType}/${queryArg.make}`,
        params: {lang: queryArg.lang, logo_url: queryArg.logoUrl},
      }),
    }),
  }),
});

export const {useGetAllEnumsQuery, useGetApiMakeModelWithMakeQuery, useGetMakeModelQuery} =
  catalogueApi;
