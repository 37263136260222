import {Card} from 'platform/components';
import {Align, Box, Center, Heading, Space, Text} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {isNotNil} from 'ramda';
import {floor} from 'ramda-adjunct';

import {i18n, MarketType, PriceReportType} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {getTestId} from '../../../../utils/getTestId';
import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {CarvagoPriceChart} from './components/CarvagoPriceChart';
import {RealSellingPriceBanners} from './components/RealSellingPriceBanners';

interface RealSellingPriceCardProps extends TestIdProps {
  priceReport: PriceReportType;
  market?: MarketType;
}

export function RealSellingPriceCard(props: RealSellingPriceCardProps) {
  const {isPrint} = useIsPrintForced();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  return (
    <Card variant={isPrint ? 'inlineWhite' : undefined}>
      <Box padding={2} overflow="hidden">
        <Align center>
          <Heading size={2} data-testid={getTestId('overview', 'realSellingPrice')}>
            {i18n.t('entity.priceMeter.labels.realSellingPrice')}
          </Heading>
        </Align>
        <Space vertical={6} />
        <Center>
          <CarvagoPriceChart
            minPrice={props.priceReport.sellingPrice?.min}
            maxPrice={props.priceReport.sellingPrice?.max}
            recommendedPrice={props.priceReport.sellingPrice?.recommended}
            data-testid={suffixTestId('priceChart', props)}
          />
        </Center>
        <Space vertical={6} />
        <Align center>
          <Heading size={3} data-testid={getTestId('overview', 'summaryPricing')}>
            {i18n.t('entity.priceMeter.labels.summaryPricing')}
          </Heading>
        </Align>
        <Space vertical={6} />
        <Text color="secondary" size="small">
          {i18n.t('entity.priceMeter.labels.summaryPricingDescription')}
        </Text>
        <Space vertical={4} />
        <Text color="secondary" size="small">
          {i18n.t(
            `entity.priceMeter.labels.summaryPricingYourVehicle.${
              (props.market?.sold?.similarVehicle ?? 0) >= 10
                ? 'knownSimilarCount'
                : 'unknownSimilarCount'
            }`,
            {
              price: formatCurrency(
                props.priceReport.sellingPrice?.recommended.value,
                props.priceReport.sellingPrice?.recommended.currency
              ),
              similarVehicles: formatNumber(props.market?.sold?.similarVehicle),
            }
          )}
          {isNotNil(props.priceReport?.turnover) &&
            ` ${i18n.t('entity.priceMeter.labels.summarySellingTimeYourVehicle', {
              days: formatNumber(floor(props.priceReport?.turnover)),
            })}`}
        </Text>
        <Space vertical={6} />
        <RealSellingPriceBanners
          priceReport={props.priceReport}
          market={props.market}
          data-testid={suffixTestId('banners', props)}
        />
      </Box>
    </Card>
  );
}
