import {Separator} from 'platform/components';
import {Box, Center, Hide, HStack, Image, Show, Stack, Text, VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useIsPrintForced} from '../../../../pages/Overview/hooks/useIsPrintForced';
import {FooterHeading} from './components/FooterHeading';
import {FooterLink} from './components/FooterLink';
import {PaymentLogos} from './components/PaymentLogos';
import {SocialNetworks} from './components/SocialNetworks';

const SHOW_SOCIAL_NETWORKS = false;
const SHOW_PAYMENT_METHODS = false;

export function Footer(props: TestIdProps) {
  const {isPrint} = useIsPrintForced();

  return (
    <Hide when={isPrint} whenFeatureDisabled="pricereport_footer">
      <Box
        borderTop="1px solid"
        borderColor="general.separator"
        backgroundColor="palettes.neutral.20.100"
        paddingTop={[8, 20]}
        paddingBottom={8}
        paddingHorizontal={8}
      >
        <Center>
          <VStack spacing={[8, 20]} width="100%" maxWidth={320}>
            <Stack direction={['column', 'row']} spacing={[10, 6, 8]}>
              <VStack spacing={8} grow={3}>
                <Image
                  src="assets/images/carsdata-logo.svg"
                  width={48}
                  data-testid={suffixTestId('carsdata-logo', props)}
                />
                <Text color="tertiary" size="small">
                  {i18n.t('entity.footer.copyright')}
                </Text>
                <Show when={SHOW_SOCIAL_NETWORKS}>
                  <SocialNetworks data-testid={suffixTestId('socialNetworks', props)} />
                </Show>
              </VStack>
              <HStack spacing={[4, 6, 8]} grow={2}>
                <VStack spacing={6} grow={1} basis={0}>
                  <FooterHeading>{i18n.t('entity.footer.services')}</FooterHeading>
                  <VStack spacing={3}>
                    <FooterLink href="https://cz.cebia.com/">Autotracer</FooterLink>
                    <FooterLink href="https://eag.group/cs/projects.html#alpha">
                      PriceReport
                    </FooterLink>
                    {/* links with href undefined are hidden – so far we don't have where to link them */}
                    <FooterLink href={undefined}>Marketdata</FooterLink>
                    <FooterLink href={undefined}>Autotracer</FooterLink>
                  </VStack>
                </VStack>
                <VStack spacing={6} grow={1} basis={0}>
                  <FooterHeading>{i18n.t('entity.footer.company')}</FooterHeading>
                  <VStack spacing={3}>
                    <FooterLink href="https://eag.group/cs/projects.html#contact">
                      {i18n.t('entity.footer.contact')}
                    </FooterLink>
                    <FooterLink href={undefined}>
                      {i18n.t('entity.footer.termsAndConditions')}
                    </FooterLink>
                  </VStack>
                </VStack>
              </HStack>
            </Stack>
            <VStack spacing={8}>
              <Separator />
              <Show when={SHOW_PAYMENT_METHODS}>
                <Stack
                  direction={['column', 'row']}
                  justify="space-between"
                  align="center"
                  spacing={8}
                  maxWidth="100%"
                >
                  <Box flex={1}>
                    <Text color="secondary" size="xSmall">
                      {i18n.t('entity.footer.payment')}
                    </Text>
                  </Box>
                  <PaymentLogos data-testid={suffixTestId('paymentLogos', props)} />
                </Stack>
              </Show>
              <Text color="tertiary" size="xSmall">
                {i18n.t('entity.footer.disclaimer')}
              </Text>
            </VStack>
          </VStack>
        </Center>
      </Box>
    </Hide>
  );
}
