const PERCENTAGE_MULTIPLIER = 100;

export function getStatisticVariant(
  myCarFeaturesLevel: number,
  soldCarsFeaturesLevel: number
): 'success' | 'warning' | 'neutral' {
  const roundedMyCarFeaturesLevel = Math.floor(myCarFeaturesLevel * PERCENTAGE_MULTIPLIER);
  const roundedSoldCarsFeaturesLevel = Math.floor(soldCarsFeaturesLevel * PERCENTAGE_MULTIPLIER);

  if (roundedMyCarFeaturesLevel > roundedSoldCarsFeaturesLevel + 5) {
    return 'success';
  }
  if (roundedMyCarFeaturesLevel < roundedSoldCarsFeaturesLevel - 5) {
    return 'warning';
  }
  return 'neutral';
}
