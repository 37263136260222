import {Flag, FlagProps} from 'platform/components';
import {HStack} from 'platform/foundation';
import {Label, ReferenceLine} from 'recharts';

interface ReferenceLineYProps {
  value: number;
  formatter: (value: number) => string;
  flagColor: FlagProps['colorScheme'];
  isFlagSubtle?: FlagProps['isSubtle'];
  flagHorizontalAlign?: 'left' | 'right';
  flagVerticalAlign?: 'top' | 'bottom';
  lineColor: string;
  isDashed?: boolean;
}

export function renderReferenceLineY(props: ReferenceLineYProps) {
  const offsetX = props.flagHorizontalAlign === 'right' ? -3 : 3;
  const offsetY = props.flagVerticalAlign === 'bottom' ? 3 : -19;

  return (
    <ReferenceLine
      y={props.value}
      stroke={props.lineColor}
      pointerEvents="none"
      strokeDasharray={props.isDashed ? 3 : undefined}
    >
      <Label
        pointerEvents="none"
        content={(p) => {
          const cartesianViewBox = p.viewBox && 'x' in p.viewBox ? p.viewBox : null;
          return (
            <foreignObject
              x={(cartesianViewBox?.x ?? 0) + offsetX}
              y={(cartesianViewBox?.y ?? 0) + offsetY}
              height={30}
              width={cartesianViewBox?.width}
              pointerEvents="none"
            >
              <HStack justify={props.flagHorizontalAlign === 'right' ? 'flex-end' : 'flex-start'}>
                <Flag
                  label={props.formatter?.(props.value)}
                  colorScheme={props.flagColor}
                  isSubtle={props.isFlagSubtle}
                  size="small"
                />
              </HStack>
            </foreignObject>
          );
        }}
      />
    </ReferenceLine>
  );
}
