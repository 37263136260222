import {FormControl, FormField} from 'platform/components';
import {Box, Stack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {head, isNil} from 'ramda';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetApiMakeModelWithMakeQuery} from '../../../store/catalogueApi';
import {useGetSupportedMakesQuery} from '../../../store/priceReportApi';
import {VEHICLETYPE_PASSENGER_CAR} from '../constants/vehiclePassengerCar';
import {VehicleFormType} from '../types/VehicleFormType';

interface VehicleFieldsProps extends TestIdProps {
  control: FormControl<VehicleFormType>;
  formApi: UseFormReturn<VehicleFormType>;
}

export function MakeModelFields(props: VehicleFieldsProps) {
  const make = props.formApi.watch('make');

  const {data: supportedMakes, isLoading: isSupportedMakesLoading} = useGetSupportedMakesQuery();
  const {data: makes, isLoading: isMakesLoading} = useGetApiMakeModelWithMakeQuery({
    vehicleType: VEHICLETYPE_PASSENGER_CAR,
    lang: [i18n.language],
  });
  const {data: models, isLoading: isModelsLoading} = useGetApiMakeModelWithMakeQuery(
    {
      vehicleType: VEHICLETYPE_PASSENGER_CAR,
      make: make ?? undefined,
      lang: [i18n.language],
    },
    {
      skip: !make,
      selectFromResult: (result) => ({
        ...result,
        data: head(result?.data ?? [])?.models,
      }),
    }
  );

  const makeOptions = isNil(supportedMakes)
    ? []
    : makes
        ?.filter((make) => supportedMakes.includes(make.make))
        .map((make) => ({
          label: make.default_label,
          value: make.make,
        }));

  const modelOptions = models?.map((model) => ({
    label: model.default_label,
    value: model.model,
  }));

  return (
    <Stack direction={['column', 'column', 'row', 'row']} spacing={6}>
      <Box flex={1}>
        <FormField
          control={props.control}
          type="choice"
          name="make"
          label={i18n.t('page.create.labels.make')}
          isRequired
          options={makeOptions ?? []}
          isLoading={isMakesLoading || isSupportedMakesLoading}
          onChange={() => props.formApi.setValue('modelFamily', '')}
          data-testid={suffixTestId('form-make', props)}
        />
      </Box>
      <Box flex={1}>
        <FormField
          control={props.control}
          type="choice"
          name="modelFamily"
          label={i18n.t('page.create.labels.model')}
          isRequired
          options={modelOptions ?? []}
          isLoading={isModelsLoading}
          data-testid={suffixTestId('form-modelFamily', props)}
        />
      </Box>
    </Stack>
  );
}
