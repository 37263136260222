import {Text} from 'platform/foundation';

import {ReactNode} from 'react';

import {isNil} from 'ramda';

interface FooterLinkProps {
  children: ReactNode;
  href?: string;
}

export function FooterLink(props: FooterLinkProps) {
  if (isNil(props.href)) {
    return null;
  }

  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      <Text color="secondary">{props.children}</Text>
    </a>
  );
}
