import {Option} from 'platform/components';

import {any, filter} from 'ramda';

export const getOptionsWithPriority = (
  defaultOptions?: Option[] | null,
  selectedValue?: (string | null)[] | null
) => {
  const selectedOptions = filter(
    (option) => any((value) => value === option.value, selectedValue ?? []),
    defaultOptions ?? []
  );
  const otherOptions = filter(
    (option) => !any((value) => value === option.value, selectedValue ?? []),
    defaultOptions ?? []
  );

  return [...selectedOptions, ...otherOptions];
};
