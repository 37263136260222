import {isNil} from 'ramda';

import {EMPTY_PLACEHOLDER} from 'shared';

export const getValueOrEmptyString: <T, X>(
  value: T | undefined | null,
  callback: (value: T) => X
) => X | string = (value, callback) => {
  if (isNil(value)) {
    return EMPTY_PLACEHOLDER;
  }

  return callback(value);
};
