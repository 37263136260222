import {Separator} from 'platform/components';
import {Box, Grid, Hide, Stack} from 'platform/foundation';

import {Children, ReactElement} from 'react';

import {useIsPrintForced} from '../hooks/useIsPrintForced';

interface ResponsiveGridProps {
  children: ReactElement[];
}

export function ResponsiveGrid(props: ResponsiveGridProps) {
  const {getResponsiveValue} = useIsPrintForced();
  const numberOfChildren = Children.count(props.children);

  const gridColumns = getResponsiveValue(
    1,
    1,
    numberOfChildren,
    numberOfChildren,
    numberOfChildren
  );
  const stackDirection = getResponsiveValue('column', 'column', 'row', 'row', 'row');
  const separatorOrientation = getResponsiveValue(
    'horizontal',
    'horizontal',
    'vertical',
    'vertical',
    'vertical'
  );

  return (
    <Grid columns={gridColumns} spacing={0}>
      {Children.map(props.children, (child, index) => (
        <Stack direction={stackDirection}>
          <Hide when={index === 0}>
            <Separator orientation={separatorOrientation} spacing={0} />
          </Hide>
          <Box flex={1} padding={4}>
            {child}
          </Box>
        </Stack>
      ))}
    </Grid>
  );
}
