import {useFormatNumber} from 'platform/locale';

import {i18n} from '@price-report/shared';

import {ChartVariant} from '../utils/chartVariant';

export function useXAxisTickFormatter(chartVariant: ChartVariant): (value: number) => string {
  const formatNumber = useFormatNumber();

  return chartVariant === 'priceMap'
    ? (value) => `${formatNumber(value)} ${i18n.t('general.metric.km')}`
    : (value) => formatNumber(value) ?? '';
}
