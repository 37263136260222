import {Rank} from 'platform/components';
import {HStack, Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {CompareTrend} from '../../../../../components/CompareTrend/CompareTrend';

interface ComparedValueProps extends TestIdProps {
  value: number;
  difference: number | Nullish;
  rankValue?: number | Nullish;
  firstScrape?: number | Nullish;
  formatter?: (value: number) => string;
  isReverseColor?: boolean;
  isAlignEnd?: boolean;
}

export function ComparedValue(props: ComparedValueProps) {
  return (
    <VStack spacing={1} align={props.isAlignEnd ? 'flex-end' : undefined}>
      <HStack spacing={1}>
        <Text size="small" alternative>
          {props.formatter?.(props.value) ?? `${props.value}`}
        </Text>
        {isNotNil(props.rankValue) && (
          <Rank percentage={props.rankValue} data-testid={suffixTestId('comparedValue', props)} />
        )}
      </HStack>
      <Show when={isNotNil(props.difference)}>
        <CompareTrend
          value={props.difference!}
          formatter={props.formatter}
          reverseColor={props.isReverseColor}
        />
      </Show>
      <Show when={isNotNil(props.firstScrape)}>
        <Text size="xxSmall" color="secondary" strikethrough>
          {props.formatter?.(props.firstScrape!)}
        </Text>
      </Show>
    </VStack>
  );
}
