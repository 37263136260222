export function getStatisticVariant(
  myCarPrice: number,
  soldCarsPrice: number
): 'success' | 'warning' | 'neutral' {
  if (myCarPrice > soldCarsPrice + 10000) {
    return 'success';
  }
  if (myCarPrice < soldCarsPrice - 10000) {
    return 'warning';
  }
  return 'neutral';
}
