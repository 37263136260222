import {getValueByDevice, useDevice} from 'platform/foundation';

import {useQueryState} from 'shared';

export function useIsPrintForced() {
  const device = useDevice();
  const [pdf] = useQueryState('pdf');
  const isPrintForced = pdf === 'true';
  const isPrint = device === 'printer' || isPrintForced;

  function getResponsiveValue<M, T, N, D, P>(
    mobileValue: M,
    tabletValue: T,
    notebookValue: N,
    desktopValue: D,
    printValue: P
  ) {
    if (isPrintForced) {
      return printValue;
    }

    return getValueByDevice(
      device,
      mobileValue,
      tabletValue,
      notebookValue,
      desktopValue,
      printValue
    );
  }

  return {isPrintForced, isPrint, getResponsiveValue};
}
