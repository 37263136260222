import {Nullish} from 'shared';

import {filterByMinMax} from './filterByMinMax';

const MILEAGE_VALUES = [
  0, 5000, 15000, 30000, 50000, 75000, 100000, 125000, 150000, 200000, 300000,
];

export const getMileageOptions = (min?: number | Nullish, max?: number | Nullish) =>
  filterByMinMax(min, max)(MILEAGE_VALUES);
