import {Box, HStack, Text} from 'platform/foundation';

import {i18n} from '@price-report/shared';

export function ChartLegend() {
  return (
    <HStack justify={['center', 'center', 'flex-end', 'flex-end']} spacing={6}>
      <HStack align="center" spacing={2}>
        <Box
          backgroundColor="palettes.neutral.60.100"
          height={4}
          width={4}
          borderRadius="circular"
        />
        <Text size="small">
          {i18n.t('entity.overviewStatisticsMileageChart.labels.legendSold')}
        </Text>
      </HStack>
      <HStack align="center" spacing={2}>
        <Box backgroundColor="severity.warning" height={4} width={4} borderRadius="circular" />
        <Text size="small">
          {i18n.t('entity.overviewStatisticsMileageChart.labels.legendStock')}
        </Text>
      </HStack>
    </HStack>
  );
}
